import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/nestor.jpg" // Tell webpack this JS file uses this image
import pal13 from "../images/palestrantes/pedromeireles.jpg" // Tell webpack this JS file uses this image
import pal14 from "../images/palestrantes/meghan.png" // Tell webpack this JS file uses this image

import pal15 from "../images/palestrantes/allan.jpg" // Tell webpack this JS file uses this image

import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import Footer from "../components/footer"

import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image
import logo5 from "../images/logos/westpoint.jpg" // Tell webpack this JS file uses this image
import logo6 from "../images/logos/atm.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image
import foto6 from "../images/fotoscursos/foto22.jpg" // Tell webpack this JS file uses this image
import foto7 from "../images/fotoscursos/foto23.jpg" // Tell webpack this JS file uses this image
import foto8 from "../images/fotoscursos/foto24.jpg" // Tell webpack this JS file uses this image
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';
import UseAnimations from 'react-useanimations';
var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
            <div className="texto inter-bold">
             <span className="opacity"> Agricultura regenerativa </span> <br></br>
              y maneio holístico<br></br>
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal14} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                     Meghan Sapp
                      </div>
                    
                    <div className="titulo inter-light">
                    Especialista en agricultura regenerativa
                      </div>
                      </div>
                    
                  </div>
                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal15} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Allan Savory
                      </div>
                    
                    <div className="titulo inter-light">
                    Biólogo, autor, defensor de la agricultura regenerativa
                      </div>
                      </div>
                    
                  </div>

            
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                        6, 7 y 8 de Marzo de 2025
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                     Espanhol
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                     600€ + IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/agricultura-regenerativa-y-pastos.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="https://docs.google.com/forms/d/1O19ws3oYgICn7_qO1s6K5QozD05mgBVP6IgnYGDy0Dw/viewform?edit_requested=true" target="_blank" >
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotomasterclass} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Descrição
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc inter-light">Nel curso de Agricultura Regenerativa y Pastos, exploraremos el enfoque
innovador del Manejo Holístico. A lo largo del programa, tendrás la
oportunidad de participar en una especial ronda de preguntas en directo con
Allan Savory, donde podrás profundizar en sus conocimientos y experiencias.
También realizaremos prácticas de toma de decisión holística y aprenderemos
sobre el monitoreo ecológico, tanto en teoría como en campo. Además, te
introduciremos a la planificación de pastoreo y de tierras de manera holística,
con prácticas que te brindarán herramientas para implementar estos
conceptos en tu entorno. Únete a nosotros para transformar tu visión sobre la
agricultura y el manejo de pastos, promoviendo un sistema más sostenible y
regenerativo.
                </div>
                {/* <div className="logos">
                  
                <img src={logo5} alt="" />
                <img src={logo6} alt="" />

                  </div> */}
            </div>
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
          <div className="text inter-regular">
          Os cursos intensivos FarmIN têm um porgrama detalhado que possibilita ao participante observar as diversas áreas abrangidas pelo curso, os diferentes módulos e a atividade dos palestrantes ao longo do dia. 
          
            </div>
      </div>
      <div className="wrapper-program">





      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            + Dia 1 - 06/03/2025
                            </Accordion.Header>

                            <Accordion.Body>
                               
                                <div class="modulo">
                                <p><span className="date">09:00 - 11:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Introducción al Manejo Holístico </span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">11:00 - 11:30</span> | <span className="mod">Coffee break</span> </p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:30 - 13:00</span> | <span className="mod">Módulo 2</span> - <span class="txt"><b>*Especial*</b> Ronda de preguntas en directo con Allan Savory  </span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Almuerzo</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:30 - 16:00</span> | <span className="mod">Módulo 3</span> - <span class="txt">Práctica de toma de decisión holística </span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:00 - 16:30</span> | <span className="mod">Coffee break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:30 - 17:30</span> | <span className="mod">Módulo 4</span> - <span class="txt">Práctica de toma de decisión holística (cont.) </span></p>
                                </div>
                               
          
                    

                                
                                </Accordion.Body>

                                </Accordion.Item>
                        

                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            +   Dia 2 - 07/03/2025
                            </Accordion.Header>

                            <Accordion.Body>
                                <div class="modulo">
                                <p><span className="date">09:00 - 11:00</span> | <span class="mod">Módulo 5</span> - <span class="txt">Introducción a la Planificación Financiera
                                Holística</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">11:00 - 11:30</span> | <span className="mod">Coffee break</span> </p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:30 - 13:00</span> | <span className="mod">Módulo 6</span> - <span class="txt">Introducción al Monitoreo Ecológicoa </span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Almuerzo</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:30 - 16:00</span> | <span className="mod">Módulo 7</span> - <span class="txt">Práctica de Monitoreo Ecológico (en campo)</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:00 - 16:30</span> | <span className="mod">Coffee break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:30 - 17:30</span> | <span className="mod">Módulo 8</span> - <span class="txt">Introducción a la Planificación de Pastoreo Holístico</span></p>
                                </div>
          
                                </Accordion.Body>
                                </Accordion.Item>


                                <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            +   Dia 2 - 08/03/2025
                            </Accordion.Header>

                            <Accordion.Body>
                                <div class="modulo">
                                <p><span className="date">09:00 - 11:00</span> | <span class="mod">Módulo 9</span> - <span class="txt">Introducción a la Planificación de Tierras
                                (infraestructura) Holística</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">11:00 - 11:30</span> | <span className="mod">Coffee break</span> </p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:30 - 13:00</span> | <span className="mod">Módulo 10</span> - <span class="txt">Visita en campo en Herdade </span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Almuerzo</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:30 - 16:00</span> | <span className="mod">Módulo 11</span> - <span class="txt">Práctica de la Planificación de Tierras Holística</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:00 - 16:30</span> | <span className="mod">Coffee break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:30 - 17:30</span> | <span className="mod">Módulo 12</span> - <span class="txt">Práctica de la Planificación de Tierras Holística (cont.)</span></p>
                                </div>
          
                                </Accordion.Body>
                                </Accordion.Item>

                                
      
                    </Accordion>
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/agricultura-regenerativa-y-pastos.pdf" target="_blank" >
                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                
                <div className="form">
                <a href="https://docs.google.com/forms/d/1O19ws3oYgICn7_qO1s6K5QozD05mgBVP6IgnYGDy0Dw/viewform?edit_requested=true" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
          </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal14} alt="" />

              </div>
              <div className="nome">
              Meghan Sapp
              </div>
              <div className="titulo">
Especialista en agricultura regenerativa                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Savory Institute y Co-hub leader de la Savory Network Hub del norte

</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Más de cinco años de experiencia en gestión holística en su granja
                  verificada por EOV, Curly Creek Ranch.
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Enseña agricultura regenerativa
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Aboga por la Alianza Europea para la Agricultura Regenerativa (EARA)

</p>
<p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
Ha pasado más de dos décadas dedicada a la transición de los
sistemas energéticos y alimentarios

</p>
                  

                 
                  </div>
              </div>
            </div>
        </div>
        </div>
        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal15} alt="" />

              </div>
              <div className="nome">
              Allan Savory
              </div>
              <div className="titulo">
              Biólogo, autor, defensor de la agricultura regenerativa
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Fundador y Presidente del Savory Institute en los Estados
Unidos

</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Fundador del Centro Africano para la Gestión Holística, en
                  Zimbabwe
</p>
<p><span className="icone"><CheckIcon></CheckIcon></span> 
Originó el concepto de la gestión holística, un enfoque de
pensamiento sistémico para la gestión de recursos.
</p>
<p><span className="icone"><CheckIcon></CheckIcon></span> 
Autor del libro “Holistic Management: A Commonsense
Revolution to Restore Our Environment”
</p>
                    
                  </div>
              </div>
            </div>
        </div>
        </div>

   
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>
      <div>
        <img src={foto6} alt="" />
      </div>
      <div>
        <img src={foto7} alt="" />
      </div>
      <div>
        <img src={foto8} alt="" />
      </div>
      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>
      <div>
        <img src={foto6} alt="" />
      </div>
      <div>
        <img src={foto7} alt="" />
      </div>
      <div>
        <img src={foto8} alt="" />
      </div>
      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
