import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import Footer from "../components/footer"
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image
import logo5 from "../images/logos/fmv.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
          <div className="texto inter-bold">
             <span className="opacity"> Curso de Auditores<br></br> Welfare Quality</span> <br></br>
              em bovinos de leite
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
               

                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal6} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      George Stilwell
                      </div>
                    
                    <div className="titulo inter-light">
                    Prof. Universitário e Consultor especializado em clínica e bem-estar de ruminantes
                      </div>
                      </div>
                    
                  </div>
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                      4,5,6 e 7 de Fevereiro de 2025
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                      Português
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      650€ +IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/WQ-bovinos-leite.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="https://forms.gle/hYGLWQXF9UkdVM1a8" target="_blank" >
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotomasterclass} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Descrição
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc inter-light">
              A rede Welfare Quality Network, desenhou o protocolo de Welfare
Quality que garante e quantifica o bem-estar em bovinos leiteiros.
Este curso é obrigatório para qualquer entidade certificadora que
pretenda ter um auditor em bovinos leiteiros, sendo de igual forma
válido para qualquer interveniente que pretenda ser auditor interno na
sua exploração/empresa.
Nesta formação irá aprender a auditar, gerar resultados de auditoria e
compreenderá todas as características da certificação de bem-estar em
bovinos leiteiros.
                </div>
                <div className="logos">
                  
                <img src={logo5} alt="" />
                <img src={logo2} alt="" />

                  </div>
            </div>
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
   
      </div>
      <div className="wrapper-program">
      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            + Dia 1 - 04/02/2025
                            </Accordion.Header>

                            <Accordion.Body>
                               
                  
                                <div class="modulo">
                                  <p><span className="date">09:00 - 10:30</span> | <span className="mod">Módulo 1</span> - <span class="txt">Generalidades sobre Bem-Estar Animal
Como avaliar e medir Bem-Estar Animal
Protocoloes Welfare Quality® e Awin
Certificação Welfair®</span></p>
                                </div>
        
                             
                                <div class="modulo">
                                  <p><span className="date">10:30 - 10:45</span> | <span className="mod">Coffee break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">10:45 - 11:30</span> | <span className="mod">Módulo 2</span> - <span class="txt">Preparação da visita</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:30 - 13:00</span> | <span className="mod">Módulo 3</span> - <span class="txt">Pré-requisitos</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:30 - 15:30</span> | <span className="mod">Módulo 4</span> - <span class="txt">Indicadores de Bem-estar de vacas de leite</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">15:30 - 15:45</span> | <span className="mod">Coffee break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">15:45 - 17:00</span> | <span className="mod">Módulo 5 </span> - <span class="txt">Indicadores de Bem-estar de vacas de leite</span></p>
                                </div>
                       
          
                    

                                
                                </Accordion.Body>

                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                              
                            + Dia 2 - 05/02/2025
                            </Accordion.Header>

                            <Accordion.Body>
                               
                  
                                <div class="modulo">
                                  <p><span className="date">09:00 - 12:30</span> | <span className="mod">Módulo 1</span> - <span class="txt">Aula prática e treino na exploração</span></p>
                                </div>
        
                             
                                <div class="modulo">
                                  <p><span className="date">12:30 - 14:30</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:30 - 16:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Discussão das práticas treinadas</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:00 - 18:00</span> | <span className="mod">Módulo 3</span> - <span class="txt">Classificação e relatório Welfare®</span></p>
                                </div>
                               
                          
                                
                                </Accordion.Body>

                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                        <Accordion.Header>
                              
                            + Dia 3 - 06/02/2025
                            </Accordion.Header>

                            <Accordion.Body>
                               
                  
                                <div class="modulo">
                                  <p><span className="date">09:00 - 10:30</span> | <span className="mod">Módulo 1</span> - <span class="txt">Exercício repetibilidades</span></p>
                                </div>
        
                             
                                <div class="modulo">
                                  <p><span className="date">10:30 - 10:45</span> | <span className="mod">Coffee break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">10:45 - 13:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Exercício repetibilidades. Dúvidas e discussão final.</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:00</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:00 - 14:30</span> | <span className="mod">Módulo 4</span> - <span class="txt">Exame escrito e amostragem</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:30 - 15:30</span> | <span className="mod">Módulo 5</span> - <span class="txt">Exame teórico-prático: repetibilidade</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">15:30 - 15:45</span> | <span className="mod">Coffee break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">15:45 - 17:00</span> | <span className="mod">Módulo 6</span> - <span class="txt">Exame teórico-prático: repetibilidade</span></p>
                                </div>
                               
                          
                                
                                </Accordion.Body>

                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                        <Accordion.Header>
                              
                            + Dia 4 - 07/02/2025
                            </Accordion.Header>

                            <Accordion.Body>
                               
                  
                                <div class="modulo">
                                  <p><span className="date">09:00 - 12:00</span> | <span className="mod">Módulo 1</span> - <span class="txt">Exame prático Individual na exploração</span></p>
                                </div>
        
                             
                          
                               
                          
                                
                                </Accordion.Body>

                                </Accordion.Item>
                        

                    </Accordion>
                    </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/WQ-bovinos-leite.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button>
                </a> 
                </div>
                <div className="form">
                <a href="https://forms.gle/hYGLWQXF9UkdVM1a8" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 

                </a>
          </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
      

        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal6} alt="" />

              </div>
              <div className="nome">
                George Stilwell
              </div>
              <div className="titulo">
              Professor Universitário e Investigador Welfare Quality
                </div>
          </div>
          <div className="body">
          <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span>Professor na Faculdade de Medicina Veterinária da Universidade de Lisboa
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Especialista em BEA em ruminantes
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span>

                  Diplomado pelo European College Bovine Health Management</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Chefe do laboratório de pesquisa em Comportamento e BEA
                  (CIISA-FMV)
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 

                  Coordenador nacional do projeto financiado pela UE - AWINAnimal
                  Welfare Indicators</p>

                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Autor dos livros - Cattle Clinics e Ruminant Welfare
                  </p>
               
                  </div>
              </div>
            </div>
        </div>
        </div>
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
