import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useState } from 'react';
import '../styles/utilitary.css'
import '../styles/styles.css'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import fotoslider1 from "../images/about/1a.jpg" // Tell webpack this JS file uses this image
import fotoslider2 from "../images/about/2a.jpg" // Tell webpack this JS file uses this image
import fotoslider3 from "../images/about/3a.jpg" // Tell webpack this JS file uses this image
import fotoslider4 from "../images/about/4a.jpg" // Tell webpack this JS file uses this image
import fotoslider5 from "../images/about/5a.jpg" // Tell webpack this JS file uses this image
import fotoslider6 from "../images/about/6a.jpg" // Tell webpack this JS file uses this image
import fotoslider7 from "../images/about/7a.jpg" // Tell webpack this JS file uses this image
import fotoslider8 from "../images/about/8a.jpg" // Tell webpack this JS file uses this image
import fotoslider9 from "../images/about/9a.jpg" // Tell webpack this JS file uses this image
import fotoslider10 from "../images/about/10a.jpg" // Tell webpack this JS file uses this image
import fotoslider11 from "../images/about/11a.jpg" // Tell webpack this JS file uses this image
import fotoslider12 from "../images/about/12a.jpg" // Tell webpack this JS file uses this image
import fotoslider13 from "../images/about/13a.jpg" // Tell webpack this JS file uses this image
import { Tooltip as ReactTooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import marca1 from "../images/logo8.png" // Tell webpack this JS file uses this image
import marca2 from "../images/montalva.png" // Tell webpack this JS file uses this image
import marca3 from "../images/logo5.png" // Tell webpack this JS file uses this image
import marca4 from "../images/logo4.png" // Tell webpack this JS file uses this image
import marca5 from "../images/logo9.png" // Tell webpack this JS file uses this image
import fundocol from "../images/fundo-col3.png" // Tell webpack this JS file uses this image
// import videotemple from "../images/videotemple.mp4"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import videobg from "../images/video-bg.mp4"
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/rtouret.jpg" // Tell webpack this JS file uses this image
import pal13 from "../images/palestrantes/imaculada.jpg" // Tell webpack this JS file uses this image
import pal14 from "../images/palestrantes/nestor.jpg" // Tell webpack this JS file uses this image
import pal15 from "../images/palestrantes/pedromeireles.jpg" // Tell webpack this JS file uses this image
import pal16 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal17 from "../images/palestrantes/temple.png" // Tell webpack this JS file uses this image
import pal18 from "../images/palestrantes/aida.jpg" // Tell webpack this JS file uses this image
import pal19 from "../images/palestrantes/samuel-boucher.jpg" // Tell webpack this JS file uses this image
import pal20 from "../images/palestrantes/luisqueiros.jpg" // Tell webpack this JS file uses this image
import pal21 from "../images/palestrantes/luisfigueiredo.jpg" // Tell webpack this JS file uses this image
import pal22 from "../images/palestrantes/humbertorocha.jpg" // Tell webpack this JS file uses this image
import pal23 from "../images/palestrantes/luis-barciaga.png" // Tell webpack this JS file uses this image
import pal25 from "../images/palestrantes/jose-dronda.png" // Tell webpack this JS file uses this image
import pal24 from "../images/palestrantes/jose-gonzalez.png" // Tell webpack this JS file uses this image
import pal26 from "../images/palestrantes/rui-cordeiro.jpg" // Tell webpack this JS file uses this image
import pal27 from "../images/palestrantes/cruz.jpg" // Tell webpack this JS file uses this image
import pal28 from "../images/palestrantes/jaime.png" // Tell webpack this JS file uses this image
import pal29 from "../images/palestrantes/samuel.jpg" // Tell webpack this JS file uses this image
import pal30 from "../images/palestrantes/peter.jpg" // Tell webpack this JS file uses this image
import pal31 from "../images/palestrantes/bas.png" // Tell webpack this JS file uses this image
import pal32 from "../images/palestrantes/robertobarcellos.png" // Tell webpack this JS file uses this image
import pal33 from "../images/palestrantes/diogogama.png" // Tell webpack this JS file uses this image
import pal34 from "../images/palestrantes/catania.png" // Tell webpack this JS file uses this image
import pal35 from "../images/palestrantes/martinez.png" // Tell webpack this JS file uses this image
import pal36 from "../images/palestrantes/dayan.png" // Tell webpack this JS file uses this image
import pal37 from "../images/palestrantes/mauro.png" // Tell webpack this JS file uses this image
import pal38 from "../images/palestrantes/jose-monteiro.png" // Tell webpack this JS file uses this image
import pal39 from "../images/palestrantes/mikel.png" // Tell webpack this JS file uses this image
import pal40 from "../images/palestrantes/felix.png" // Tell webpack this JS file uses this image
import pal41 from "../images/palestrantes/ramon.png" // Tell webpack this JS file uses this image
import pal42 from "../images/palestrantes/david.png" // Tell webpack this JS file uses this image
import pal43 from "../images/palestrantes/pepe.png" // Tell webpack this JS file uses this image
import pal44 from "../images/palestrantes/abuelo.png" // Tell webpack this JS file uses this image
import pal45 from "../images/palestrantes/bart.png" // Tell webpack this JS file uses this image
import pal46 from "../images/palestrantes/cavalcanti.png" // Tell webpack this JS file uses this image
import pal47 from "../images/palestrantes/segales.png" // Tell webpack this JS file uses this image
import pal48 from "../images/palestrantes/bexiga.png" // Tell webpack this JS file uses this image
import pal49 from "../images/palestrantes/pablo.png" // Tell webpack this JS file uses this image
import pal50 from "../images/palestrantes/menini.png" // Tell webpack this JS file uses this image
import pal51 from "../images/palestrantes/cabrera.png" // Tell webpack this JS file uses this image
import pal52 from "../images/palestrantes/avatar.png" // Tell webpack this JS file uses this image
import pal53 from "../images/palestrantes/meghan.png" // Tell webpack this JS file uses this image
import pal54 from "../images/palestrantes/allan.jpg" // Tell webpack this JS file uses this image


import reagendamento from "../images/reagendamento.png" // Tell webpack this JS file uses this image

import esgotado from "../images/esgotado2.png" // Tell webpack this JS file uses this image
import brevemente from "../images/brevemente.png" // Tell webpack this JS file uses this image

import icone1 from "../images/icones/bovinos-carne.png" // Tell webpack this JS file uses this image
import icone2 from "../images/icones/bovinos-leite.png" // Tell webpack this JS file uses this image
import icone3 from "../images/icones/aves.png" // Tell webpack this JS file uses this image
import icone4 from "../images/icones/suinos.png" // Tell webpack this JS file uses this image
import icone5 from "../images/icones/ruminantes.png" // Tell webpack this JS file uses this image
import icone6 from "../images/icones/coelhos-resize.png" // Tell webpack this JS file uses this image
import icone7 from "../images/icones/agro.png" // Tell webpack this JS file uses this image

import Slider from "react-slick";
import scrollTo from 'gatsby-plugin-smoothscroll';

   var settings = {
      infinite: true,
      autoplay:true,
      speed: 1000,
      autoplaySpeed:3000,
      slidesToShow:5,
      slidesToScroll: 1,
      arrows:true,
      centerMode:false,
      swipeToSlide: true,
      draggable:true,
      gap:15,
   };
   var settings2 = {
      infinite: false,
      autoplay:true,
      speed: 1000,
      autoplaySpeed:3000,
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows:true,
      centerMode:false,
      swipeToSlide: true,
      draggable:true,
      gap:15,
   };
   var settings3 = {
      infinite: false,
      autoplay:true,
      speed: 1000,
      autoplaySpeed:3000,
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows:true,
      centerMode:false,
      swipeToSlide: true,
      draggable:true,
      gap:15,
   };



    var settingsMobile = {
      infinite: true,
      autoplay:true,
      speed: 1000,
      autoplaySpeed:3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:false,
      centerMode:true,
      swipeToSlide: true,
      draggable:true,
    };

    var settingsaboutmobile = {
      infinite: true,
      autoplay:true,
      speed: 1000,
      autoplaySpeed:3000,
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows:false,
      centerMode:false,
      swipeToSlide: true,
      draggable:true,
    };
    var settingsaboutdesktop = {
      infinite: true,
        autoplay:true,
        speed: 1000,
        autoplaySpeed:3000,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows:false,
        centerMode:false,
        swipeToSlide: true,
        draggable:true,
    };
    
const IndexPage = () => {
   const [showPopup, setShowPopup] = useState(false); // Estado para controlar o popup

   const openPopup = () => {
     setShowPopup(true); // Abre o popup
   };
 
   const closePopup = () => {
     setShowPopup(false); // Fecha o popup
   };
 
   return (
<Layout>
   <div id="section-1" >
      <div className="wrapper-container">
         {/* <video className="video-class"  preload='auto'  autoPlay loop playsInline muted poster=
"https://farmin-trainings.net/poster-video.png" >
            <source src={videobg}  type="video/mp4" />
         </video> */}
         <div className="absolute-element">
			 <div className="contentor-geral">

			
			 <div className="contentor">
            <div className="wrapper-2">
               <div className="left-col">
                  <div className="left-element">
                     <div className="titulo">
                        <p className="color-white inter-thin">
                           A nova era de  <span className=" palavra color-white inter-bold"> formação
                               
                              <svg version="1.1" className="circulo" id="Camada_1" x="0px" y="0px"
                                 viewBox="0 0 562 189.6"  >
                                 <g>
                                    <path class="st0" d="M554.8,94.7c0.4,0.6,1.4,0.2,1.1-1.4c-0.6-0.4-1.1-0.7-1.7-1C554.4,93.1,554.6,93.9,554.8,94.7z"/>
                                    <path class="st0" d="M555.4,83.2c-0.8-0.8-1.7-1.5-2.6-1.8c-0.9-0.3-1.6-0.1-2,0.8c0.6,0.2,1,0.4,1.3,0.7c0.3,0.2,0.5,0.4,0.8,0.7
                                       c0.5,0.5,1.1,0.9,2.3,1.6c0.2,0.4,0.3,0.8,0.4,1.1c0.1,0.4,0,0.7,0,0.9c-0.1,0.5-0.3,0.8-0.7,0.8c0.1,0.1,0.2,0.3,0.3,0.6
                                       c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.7,0.3,1.3,0.9,1.7c1-2.3,0.3-5.4-1.9-7.6C554.4,82.8,555,83.2,555.4,83.2z"/>
                                    <path class="st0" d="M401.6,14.3l1.1,0.4C402.3,14.5,402,14.4,401.6,14.3z"/>
                                    <path class="st0" d="M294.4,4.9c0.8,0,1.2-0.2,1.2-0.3C294.9,4.7,294.2,4.8,294.4,4.9z"/>
                                    <path class="st0" d="M276,3.1c0.4,0.1,0.7,0.1,1.1,0.1C276.7,3,276.3,2.9,276,3.1z"/>
                                    <path class="st0" d="M401.6,14.3l-2.8-0.9C398.5,13.9,400.2,14,401.6,14.3z"/>
                                    <path class="st0" d="M538.2,67.8c0.5,0.3,0.9,0.5,1.4,0.6c-0.4-0.3-0.8-0.5-1.2-0.8C538.3,67.7,538.3,67.8,538.2,67.8z"/>
                                    <path class="st0" d="M448.5,21.9l0.9,0.2c0.3-0.1,0.6-0.3,0.9-0.5L448.5,21.9z"/>
                                    <path class="st0" d="M483.8,31.5c-0.8-0.3-1.5,0.9-2,1.4c0.6-0.4,1.3-0.5,2-0.3C483.9,32.3,483.9,32,483.8,31.5z"/>
                                    <path class="st0" d="M535.3,58.5l1,0.4C536,58.8,535.6,58.6,535.3,58.5z"/>
                                    <path class="st0" d="M422.9,16.2c0-0.2-0.1-0.4-0.2-0.6C422.7,15.8,422.8,16,422.9,16.2z"/>
                                    <path class="st0" d="M532.1,123.4c0,0.1,0.1,0.1,0.1,0.2C532.3,123.4,532.3,123.3,532.1,123.4z"/>
                                    <path class="st0" d="M558.4,94.7l0.1,0.6c0.3,0.1,0.5,0.1,0.8,0.1L558.4,94.7z"/>
                                    <path class="st0" d="M242.2,2.1c0.1,0,0.1,0,0.2,0C243.3,1.9,242.8,2,242.2,2.1z"/>
                                    <path class="st0" d="M23,132.9c-1.1-1.6,0.3-1,1.6-0.6c1.3,0.4,2.3,0.6,0.5-1.5C25.2,131.6,19.9,129.5,23,132.9z"/>
                                    <path class="st0" d="M537.6,67.1c-0.4-0.3-0.7-0.6-1.1-0.9C537,66.7,537.3,66.9,537.6,67.1z"/>
                                    <path class="st0" d="M25.1,130.8L25.1,130.8l-0.4-0.4C24.9,130.6,25,130.7,25.1,130.8z"/>
                                    <path class="st0" d="M164.8,10.1c0.2,0.2,0.5,0.4,0.9,0.5C165.6,10.4,165.4,10.3,164.8,10.1z"/>
                                    <path class="st0" d="M222.6,6l-1.4-0.1C221.7,6,222.2,6,222.6,6z"/>
                                    <path class="st0" d="M135.5,17c-0.8,0-1.1-0.1-2.1,0.3c0.1,0.1,0.2,0.1,0.4,0.1C134.1,17.2,134.6,17,135.5,17z"/>
                                    <path class="st0" d="M104.9,23.9c0.2,0.1,0.4,0.1,0.6,0.1c0.3-0.1,0.6-0.2,0.9-0.3L104.9,23.9z"/>
                                    <path class="st0" d="M447.4,158c0.7,0.2,1.3,0.5,1.7,0.9C448.4,158,452.7,156.6,447.4,158z"/>
                                    <path class="st0" d="M538.7,67.2c-0.4,0.2-0.7,0.1-1.1-0.1c0.3,0.2,0.5,0.4,0.8,0.6C538.6,67.6,538.8,67.4,538.7,67.2z"/>
                                    <path class="st0" d="M136.3,28.1c-1.6,0.2-3.2,0.5-4.8,0.8C132.9,28.7,134.6,28.4,136.3,28.1z"/>
                                    <path class="st0" d="M227.4,174l1.1-0.1C228.1,173.9,227.7,173.9,227.4,174z"/>
                                    <path class="st0" d="M117.4,32.1c-1-0.3-1.9-0.3-2.9-0.3c-0.2,0.2-0.4,0.3-0.5,0.5L117.4,32.1z"/>
                                    <path class="st0" d="M449.1,158.9C449.1,158.9,449.1,158.9,449.1,158.9C449.1,158.9,449.1,158.9,449.1,158.9z"/>
                                    <path class="st0" d="M479.1,147c-4.4,1.4-1.5,2.4-2.1,3.7c-5.1,2.2-2.8-0.5-4.9-0.9c-3.1,1.5-5.2,3.8-7.7,4.4
                                       c-0.3-0.2-0.8-0.2-0.3-0.7c-3.1,2.4-10.5,2.9-14.2,5.7c-0.3-0.1-0.5-0.2-0.7-0.3c0.9,0.7,1.2,1.6,1.2,2.3c-2,0.5-3.5,2-4.6,1
                                       c1.3-0.3,1.3-0.8,1.8-1.3l-2,1c-2.3-1.2,2.8-1.3,2.5-2.5c-4,1.5-8.6,4-11.9,4.2c-1-1.5,7.4-2.2,2.8-2.7l5.1-0.5
                                       c-4.4-1.7,3-0.5-1.1-2.5c-6.6,1-7.1,2.5-13.7,3.3c-1.3,1.2,0.8,1.3-0.5,2.5c-4.5,1.7-6.5,1-8.2,0.3c-1.7-0.7-3.1-1.4-6.3,0.3
                                       c-1.6,1.4-1.9,3.6-7,3.3l1.4-2.2c-6.4,0.8-3.2,3.3-10.1,3c1.1-0.9,4.9-1.4,3.9-1.5c-4.6-0.8-2.9,1.5-6.2,2l-0.9-1.6
                                       c-6.8,1.2-14.1,3.4-21.7,4.6c0.4-0.5,1.6-1.2-0.2-1.5c-9.5,1.9-20.5,1.9-29.8,1.5l-3.4,1.7c-0.8,0-1.6-0.6-0.7-0.9
                                       c-3.6-0.3-0.1,0.9-1.8,1.6c-2,0.2-2.8-0.7-1.5-1.2c-6.3,1.7-18,3.7-24.8,2.3l1.3-0.5c-5.2-0.4-5.8,3.2-10.7,1.9l0.9-0.3
                                       c-11.4-0.4-24,1-32.6-1.3c-1.7,2.1-11.2-1.1-12,1.8c-6.5-1.6-4.6-2.1-13.7-3.4c-2.4-0.2-3.7,1.7-6.7,1.8l0-1.5l-4.2,1.4l-2.1-2
                                       l-2.8,0.2c1.1,0.2,2.4,0.8,2.2,1.3c-4.5,0.4-10.7-1.8-10.7-2.5c-3.5,0.2-7,0.3-10.5,0.5c0.3-0.6,2-0.7,3.5-0.9
                                       c-5.7,1.1-0.9-1.7-6.1-0.7c0.7,0.4-0.4,0.9-0.6,1.2c-2.5-1.2-8.2-1.7-12.5-1.8l0.9-1c-7.7-1.1-16.3-1.3-24.9-2.5
                                       c3,0.9,2.1,2.6-0.5,2.5l-0.8-1.7c-1.8,0.8,2.6,1-1,1.7c-3.2-0.7-1.9-2.1,0-2.4l-4,0c-0.2-0.5,0.8-0.6,1.1-1.2
                                       c-1.8,0.8-6.5-2.6-9-1.3l-0.1-0.5c-8.5-1.4-12.5-1.6-19.4-2.9c-0.4,1.3-3.1,0.5-5.3,1.2c-5-1.5-10.4-3.8-16.5-5.1
                                       c-4.4-0.4-1.5,0.8-3.6,1.2c0-1.3-6.1-2.7-2.7-2.8c-3.6-0.8-6-1.4-8.6-1.9c-2.6-0.5-5.4-1.1-10.1-2c0.8,0.8,1.9,1.4,1.2,1.6
                                       c-1.7-0.3-3.6-1.7-3.2-2.2l0.5,0c-5.4-0.7-10.9-3.5-16.1-6.3c-5.2-2.8-9.9-5.6-14.3-6.1l0.5,0.1c-2.8-0.8-5.6-1.6-8.4-2.4
                                       c-3.5-2,2.6,0.2,0.3-1.8c-4.6-1.1-7.8-3-10.7-4.9c-2.9-1.9-5.4-3.8-8.8-5.5c-1.2-3.2-5.2-6.2-8.8-9.7c-3.7-3.4-6.9-7.7-7.1-11.2
                                       l-0.7,0.7c-0.4-0.3-0.6-0.6-0.7-0.9c-0.1-0.3-0.1-0.5,0-0.8c0.1-0.5,0.3-1,0.2-1.6c-0.7,0.4-0.1,2-1,1.1c-1.5-3.4-1.9-7.1-1.3-10.8
                                       c0.3-1.8,0.9-3.6,1.7-5.4c0.8-1.7,1.8-3.4,3-5c2.4-3.2,5.4-6.1,8.7-8.8c3.3-2.6,6.9-5,10.6-7.3c2.7-1.7,2.9-3.1,3.3-4.5
                                       c0.5-1.3,1.3-2.7,5.4-3.8l-1.2,2.6c5.2-4.1,11.3-7.1,17.5-9.7c6.2-2.6,12.6-4.9,18.5-7.9c-0.6,0.6-0.5,1.1-1.7,1.5
                                       c3,0.2,9.7-3.2,9.5-4.1c2-0.5,0.7,0.7,2.1,0.8c2.9-0.2,6.3-3.5,8-2.7c0.3,0.1-0.6,0.6-1.1,0.8c1.6-1.1,8.2-2.1,6.1-3
                                       c4.9-0.7,9-3,13.1-3.1c2.2-2.1,8.4-1.2,8.8-3.5c0.1,1.1,2.3,1.1,4.9,0.6c2.6-0.5,5.5-1.5,6.9-2.3c-0.9,0.9,2.8,0.2,1.8,1.3
                                       c3.5-0.7,6.7-1.7,5.8-2.7c7,1.5,12.8-4.4,17-1.6c10.3-2.8,20.1-4.9,29.6-6.5c-0.1,2.2-10.5,1.4-9.8,3.8c8.9-2,14-4.5,23-4.8
                                       c2.4,0.1-1.2,1.1-2,1.5c9-1.8,17.6-1.3,25.6-3.1c0,0.2-0.9,0.5-1.7,0.7c1.6-0.2,3.7,0.1,3.5-0.9l-1.5,0.4c-2.7-1.3,6.2-3.5,6.4-4.3
                                       c-2.6,3.1,8.1,0.3,3.9,4.2c1-0.2,2.3-0.8,2.1-1.5c1.6,0.1,2.1,0.5,0.5,1.3c5.7-0.8,3.6-1.8,8.8-2c0,0.5-1,0.7-1.6,1.3
                                       c2.6-1,5.2-2.1,9.6-1.8c-0.3,0.3,0.3,0.7-1,0.7c7.5-0.4,12.8-2.9,21.1-2c-2.4,2.1,2.8,0.7,3.3,2.5l4.4-0.6l-1.8-0.9
                                       c3.9,0,7.4-1.3,10.7-0.6L290,12l7.3-0.3l-4.1-0.8c4-0.9-0.4-2.4,4.8-1.8c-1.1,0.2,3.9,0.1,5.9,1.2l0,0c1.9-0.5,4-1.1,6.8-0.6
                                       c0.7,0.6-0.7,2-0.2,2.4c2-1.5,9.1-0.1,11.6-1.6c-0.8,0.4-0.9,1.3-0.7,1.5l1.7-1.2c1.8,0.5,2,0.7,1.7,1.5c5.4,0.5-1.6-2.4,4.8-1.1
                                       c-0.5,0.1-0.3,0.3-1.3,0.4c6.4,0.9,12.6-0.1,18.5,1c1.6,2-5.2,0.1-3.3,1.8c8.6,0.3,17.9-0.8,26.1,0.5l-1.5-0.4
                                       c1.4-2.5,6,1.9,10.3,1.2c-1,0.1-1.5,1.3-1.2,1c5.6,1.1,11.6,0.8,20.3,1.2l-1.2,0.5c3.2,1.1,8.7,1.6,12.2,2.5c1.2-0.5,3.2,0,2.1-1.2
                                       c5.2,2.8,10.5,3.2,15.7,3.6c5.2,0.4,10.2,0.8,14.7,3.4c-1.2-0.2-2.9,0-2,0.2c2.8,0.4,5.6,0.8,8.3,1.3c-0.4-1.3-0.9-0.9-2.4-2.2
                                       c1.2-0.4,3.6-0.4,5.7,0.1c-3.9-0.1-0.4,1.8,1,2.8c-0.2-0.4,0.5-0.6,0.4-1c3.8,3.1,1.3-2.1,6.8,0.8l-0.5,0.5c2.3,0.3,4.5,0,6.8,0.9
                                       c0.6,1.5-2.5,0-2.9,1c3.3,0.8,7.2-0.6,9.8,1.6c-1.5-0.1-3.5-0.6-5-0.7c2.8,2.4,3.3,2.1,5.6,3.1l-0.2-0.1c1.8,0.8,3.5,1.6,5.3,2.4
                                       l-1.3-0.8c5.1-2,8.4,2.1,14.6,1.9c-6,0.1-1.3,0.9-2.2,2.1c1.2,0.9,3.1,2.1,2.4,2.3c3-0.2,3.8,2.5,8,2.2c0,0.5,2.6,1.1,1.6,2
                                       c3.4,1-0.4-1.1,0.1-1.5c2.8,0.3,5.5,2,8.5,3.8c3,1.9,6.1,3.8,10,4.6c1.6,0.6,2.9,1,4,1.3c1.1,0.3,2.1,0.6,3.1,0.8
                                       c2,0.5,3.9,1,6.7,2.4c0.7,0.9,0,1-0.7,0.9c-0.8-0.1-1.7-0.4-1.7-0.4c2.4,2.5,4.5,3.8,7.3,5.4c-0.8-0.1-0.5,0.6-0.1,1.6
                                       c1,0.7,1.6,0.7,2.2,0.8c0.6,0.1,1.3,0.3,2.2,1.7c-0.8,0-1.9,0.1-3.2-0.2c2.5,1.6,5.2,3,7.5,5c-0.1,1.2-2.1-0.6-3.3-0.9
                                       c0.3,0.3,0.7,0.6,1,0.9c0.3,0.3,0.7,0.6,1.1,1c0.7,0.7,1.4,1.4,2.1,2c1.4,1.3,2.6,2.4,3.4,2.5c-0.2-0.7-0.5-1.4-0.8-2.1
                                       c0.8,1-0.6,1.2-1.3,0.6c-2.5-1.7-2.2-3.6-1.9-4c0.6,0.5,1.1,1,1.6,1.5c0.2-0.9-0.1-1.8-0.7-2.6c-0.6-0.8-1.5-1.6-2.5-2.4
                                       c-1-0.8-2.2-1.4-3.3-2.1c-1.1-0.7-2.1-1.4-3-2.1c0.9-0.1,1.8-0.3,2.8-0.3c-1.5-0.6-3.1-1.1-4.7-1.6c-0.4-1-0.3-2.4,1.7-1.2
                                       c-2-2.8-2.7-1.2-4.3-3.7c-0.2-0.3,0-0.3,0.3-0.2c-1-0.5-2-0.9-3-1.3c0.8,0,0.4-1.5,1.8-0.2c-2.1-2.1-4.3-3-6.1-3.7
                                       c-1.8-0.7-3.2-1.1-3.7-2.3l0.5,0.1c-3.7-1.7-4-1.4-4.2-0.9c-0.2,0.5-0.2,1.2-3.3,0.6c-0.6-1.7-1.2-3.4-1.9-5.1l-1.4,1
                                       c-0.7-0.4-2.1-1.2-1.6-1.6c-3.2-0.4,0.2,0.8-1.8,1c-3-2.9-10.2-3.7-10.1-6.3c-1.7,0.5,3.2,2.3-1,1.4c-0.2-2.9-4.7-1.4-7.6-3.7
                                       c1.5-0.9,4.5,1,1.5-1.4c-2.8,1.4-6.5-2.8-9.5-3.4c-0.7,1.6-6.5-1.4-11.6-2.2c0.5-0.5-8.9-3.7-13.4-5.4l0.2-0.3
                                       c-1.4,1.5-6,0-9.6-0.5c0.4-1,1.1-1.3,3.1-1.3l-3.3-0.8c-0.9,0.4-2,0.4-4.2,0.2l1.1-1.1c-4.4-0.9-9.1-0.4-14.6-2.4
                                       c-0.6,1.2,3.7,4.4-2.3,4c-0.1-0.7-0.1-1.9,1.5-1.9c-0.5,0-1.9,0.2-2.9-0.3l2.6-0.7c-2.5-1-1.5,0.7-3.6,0.2c0.2-1-1.4-1-0.9-1.7
                                       c0.5,0,1.7,0.7,2.6,0.5c-2.3-0.6-5-1.7-5.8-2.6c0.1,0.6-0.2,1.3-2,1.1c-4.4-0.7-1.3-1.4-3.8-2.4c-1.2,0.5-3.9-0.7-4.2,0.7
                                       c-1.3-0.2-1.4-0.9-1.8-1.4c-2.4,0.2-10.7-1.5-8.4,0.4l0.6,0.2c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0l0,0c-7.7,0.3-15.4-3.1-23-3.3
                                       l0.1-0.5c-4.5,0-9.9-0.8-13.2-0.1c-1.3-0.1-3-1.2-2.4-1.7c-3.4,0-3.9,0.5-8.7-0.7c3.5-0.6-1.1-0.9,3.6-0.3
                                       c-4.4-0.8-5.3-1.7-10.2-0.7c1.7-1.1-3.2-1.8-5.4-1.6l3,1.1c-2.4,0-4.5-0.3-6.6-0.5l1.3-1.6c-9-2.6-16.6,2.4-23.4-1.2
                                       c-2.5,1,4.9,1,2.7,2.3c-4.2-0.4-8.4-2.9-9.8-3c-5.7-1.1-5.4,2.2-11.4,1.5c0.8,0.6,1.4,2.2-3,2.7c-4.2-0.3-5.1-2.5-2.5-3
                                       c1,0,1.5,0.2,1.4,0.4c1.4-0.2,3.1-0.4,1.5-1.1l-0.6,0.6c-2.1-0.6-7.8-0.6-6.5-1.6c-3.2,0.5-1.4,1,1,1.3c-5.4-0.9-8.4-0.1-13.9-0.6
                                       c0.9,0.3,1.7,1,0.8,1c-8.1-0.7-3.6,1.1-9.1,1.5c-3.1-0.9,1.1-2.2-4.4-1.3c-3.4-0.5-0.7-1.5,0.6-2.1c-5,1.2-9.5-0.5-12.4-0.6
                                       l2.7-0.6c-1.6,0.3-2.9,0.4-4.2,0.4l1.6,1.1c-1.6-0.1-2.9-0.1-2.9-0.6c-1.6,0.8,1.3,1.9-1.9,3.1c-2.1-0.5-6.3,0.9-7.1-0.6
                                       c6.6,0.2-0.8-1.5,4.7-2c-1.1,0.2-2.4,0.3-3.9,0.1c-0.2-0.2,0.2-0.3,0.6-0.4C235.9,1,239.4,4,233,4.3c0.8-0.9-1.6-1.6-2.6-1.3
                                       c2.6-0.1,1.3,1.4-1,2.2c-4.2-0.4-4.5,0.8-6.7,0.9l2.5,0.2c-0.8,0.9-2.9,1-5.7,1.4C219.1,7,222,7,220.9,6.7
                                       c-3.1,1.8-7.4-1.1-11.8,0.3c-2.1-0.4-0.3-1.6-2.2-1.9c-4.9,1.6-5.6-0.7-10.8,0.7c1.6,1,1.9,0.7-1.7,2.2c2.9-0.6,5.7-1.1,8.6-1.7
                                       l-5.4,2.2c2.6-0.2,5.4-1.3,7-1.2c-2.6,1.2-2.3,0.9-1,1.8c-3.7-1.4-7,1.7-11.2,0.9l-0.2-2.9c-6.4-0.6-10.9,2.7-19,3l2.7,0.2
                                       c-0.9,1.3-4.9,0.8-7.5,1.6c0.1-1-1.6-1.1-2.8-1.6c0.2,0.7-5.9,0.8-6.3,2.9l-3.8-0.7c-6.6,0.5-6.9,4-13.2,4.7
                                       c1.7-1.4-1.3-1.6,2.8-3.1c-2.1,0.3-4,0.5-3.9,1.2c-1.3-0.4-5.5,2.4-7.6,1.8c-0.8,0.6-0.9,1.6-3.2,2c-0.3-0.1-0.1-0.5-0.1-0.5
                                       c0.1,0.5-3.5,1.4-1.2,2c-3.5-0.9-7.9,0.2-12.2,1.3c-4.3,1.1-8.4,2.3-11.5,1.7c-4.4,1.5-9.6,3.2-13.6,4.5c0.2-0.4-0.1-0.5,0.7-0.6
                                       c-2.9,0.4-3.5,1.3-4.3,2.2c-0.7,1-1.6,1.9-4.8,2.4c-3.7-0.5,3.1-1.9,0.9-1.8c-1.9-2.8-7.4,2.8-12.2,2.6l1-0.5
                                       c-3.6,0.1-7.4,4-12.6,5.8c0,0,0.1-0.4-0.2-0.5c-2.9,2.9-9.2,6.3-13.8,9.1c0.9-1.9,1.9-1.7,0.4-2.4c-2.1,1.2,1.5,1.5-2.5,4
                                       c-1.7,0.1-5,1.2-6.1,0.2c1.3-0.8,2.6-1.5,4-2.2c-2.9-0.5-5.2,2.8-7.4,3.8l-0.1-0.8c-1.5,0.9-2.5,1.8-3.4,2.6
                                       c-0.8,0.8-1.5,1.5-2.1,2.2c-1.3,1.4-2.6,2.8-5.6,5.1l1,0c-1,2-1.9,2.3-2.7,2.6c-0.9,0.3-1.8,0.5-2.9,2.4L17,64
                                       c-0.4,0.6-1,1.4-1.6,2.3c-0.6,0.9-1.4,2-2.1,3.2c-1.5,2.3-2.9,4.9-4.1,7.1c0.1-0.8,0.3-1.7,0.7-2.7c0.4-1,0.8-2,1.4-3
                                       c1.1-2,2.4-4.1,3.4-5.7c-1,1-2.6,2.7-3.9,4.5c-1.3,1.8-2.3,3.7-2.3,4.5c0.5-1.1,1-2.5,1.9-3.2c-0.9,3.7-2.9,7.6-4.9,12.7
                                       c-0.8-0.6-1.6,0-2.3,1.2c-0.3,0.6-0.7,1.5-1,2.4c-0.2,1-0.6,2-0.7,3.1c-0.4,2.2-0.4,4.7-0.5,6.8c0,1.1,0,2.1-0.1,3
                                       c0,0.9-0.2,1.7-0.4,2.3c1,2.6,2.1,5,3.2,7.4c1.2,2.3,2.4,4.7,3.9,6.9c2.8,4.5,6.3,8.7,10.8,12.3c-0.6-2.5,0.1-1.9,1-1.1
                                       c1,0.8,2.3,1.8,3,0.4c2.2,1.4,2.9,2.1,3,2.3c1.7,2,3.5,4,5.4,5.9c1.1,1.7-2.4-0.8-2.6-0.1c2.3,1.7,2.7,2.2,2.8,2.8
                                       c0.1,0.5-0.1,1,1.4,2.3c-0.1-1.3,2.5-0.3,5.1,1.6c-1.1-1.2-2.2-2.3-3.3-3.5c3.8,1.3,5,2.7,6.2,4c1.2,1.4,2.5,2.8,6.4,3.8
                                       c-0.9-0.1-1.8-0.2-2.7-0.3c2,0.7,5.4,2.3,8.9,3.6c3.5,1.3,7.2,2.3,9.4,1.9c0.5,0.6,1.1,1.7-0.6,1.3c4.4,2,9.7,3.2,14.7,4.4
                                       c5,1.2,9.7,2.2,13,4l-0.9,0.5c1.8,0.3,3.4-0.5,5.3,0.9c-0.2,0.3-0.9,0.5-0.1,0.8c1,0,4.9,0,7.1,1.1l-2.2,0.3
                                       c8.8,3.4,14.4,1.6,22.1,2.8c-0.5,2.1,6.5,4.4,10,5.4l-0.2,0.3c10.6,2.4,18.5,1.6,28.5,2.3c2.9,1.7,8.1,2.7,13.8,3.5
                                       c5.7,0.9,11.8,1.6,16.8,2.8c-1.5-0.6,0.4-1.7,2.2-1.7c2.3,0.7,6.6-0.9,6,1.1l-0.8-0.1c1.6,2.1,6.4,2.1,10.2,2.5l0.8-2.6
                                       c4.7,2.3,11.2,2.5,17.1,2.4c5.9,0,11.2-0.2,13.8,1.6c2.4-0.5,5.3-0.4,7.7-1l-0.2,1.4c6.6,0.6,9.8,0.9,18,0.7l-1.7,1
                                       c4.6,0.2,5.9-3.9,10.1-4l1.3,2.1c8,0,16.1,1.8,24-0.2c-0.1,0.3-0.3,0.9-1.6,1c3.8,0,14.6,1.1,13.2-1.8c0.2,1.9,8.3-0.1,12.5-1.1
                                       l-0.6,1.4c3.6-1.9,3.6-2.4,8.7-3.2c1.2,0.1,1.1,1.1-0.5,0.8c4,0.5,3-1.5,7.9-1l-1.8,1c4.1-0.1,3.8-0.6,6-1.8c2.5-0.7,7.8-0.2,8.5,1
                                       c0.5-0.8,2.7-2,5.1-1.9c0.4,0.4-0.5,0.8-0.9,1c7,0.3,10.1-2.5,14.9-1.7c0.1,0.4,0.5,0.6,0.9,0.7c-5.5,0.5-9.9,0.9-9.5,0.7
                                       c0.1,1.3,4.6,1.1,1.2,3.2c3.5-1.3,9.3-4,13.7-3.9c0.2,0.1,0.4,0.2,0.6,0.4l-0.4,0.2c1,0,1.9-0.2,2.7-0.5c1.4,0,2.3,0.1,3.6,0
                                       c0.6,1,5.1-0.1,7.3-0.3c-0.4-1.4,2.5-1.5-0.3-2.3c0.4,0.1-0.4,0.2-1.8,0.5c-0.3,0.1-0.7,0.2-0.9,0.3c0,0,0.1-0.1,0.1-0.1
                                       c-1.4,0.2-3.3,0.5-5.4,0.8c1.7-0.9,3.4-1.7,5.9-1.3c1-1,2.1-2.1,2.8-2.6c3.2,1.5,9.2,1.8,10.2,2.8c6.6-1.7,15.7-3,19.4-6.3
                                       c1.8-0.7,2.3,0.3,3.1,0.6c-1,0.5-2.3,0.7-2.9,1.1c3.7-1.3,11.1-2.4,11.7-4.4c4.9-0.2-2.3,2.2,4.1,0.6l-0.8,0.7
                                       c6.7,0.1,11.7-2.2,16.8-4.6c5.1-2.3,10.3-4.7,17.8-4.8c-1,0.1-1.5,0.1-1.8-0.1c8.2-1.4,16.2-4.4,24.2-7.5
                                       c7.9-3.2,15.7-6.5,23.7-8.9c4.5-1.6,4.4-2.9,6.5-5l1.6,0.8c1.1-1.3,2.3-2.6,3.4-3.9c2.7-2.3,5.6-3.1,8.8-4.3c3.1-1.2,6.4-2.9,9-6.8
                                       l-1.5,0c0.6-0.5,1.4-1.2,2.2-1.9c0.8-0.8,1.6-1.6,2.3-2.3c0.7-0.8,1.3-1.4,1.8-1.8c0.5-0.4,0.8-0.6,1-0.3c0.1-0.4-0.1-1.5,1.1-2.1
                                       l0.7,0.4c1.3-2.4,2.5-4.9,3.4-7.4c1.7-2.6,2.2-2.1,3-1.9c0.4,0.1,0.7,0.1,1.3-0.5c0.3-0.3,0.6-0.7,0.9-1.4c0.3-0.7,0.8-1.5,1.1-2.8
                                       c0.4-1.8,0.3-4.6-0.1-6.6c-0.5-2-1.1-3.3-1.4-2.6c0,0.7-0.2,1.6-0.5,2.3c-0.3,0.7-0.7,1.2-1.1,1.2c0-0.5,0-1,0-1.5l-0.1-1.5
                                       c-1.1-0.3-2.3-1-3.4-0.7c-0.5,0.1-1.1,0.5-1.6,1.1c-0.5,0.6-1,1.6-1.7,2.9c0.2,1.4,0.3,2.9,0.3,4.4c-0.5,0.3-1,0.6-1.5,0.9
                                       c0.4-1.1,0.8-2.2,1-3.3c-0.4,0.4-0.6,0.8-0.8,1.1c-0.2,0.4-0.3,0.7-0.3,1.1c-0.1,0.7,0,1.5,0,2.3c-0.7,0.1-1.3,0.4-1.9,1
                                       c0,0.8,0,1.6-0.1,2.4c0,0.8-0.2,1.6-0.3,2.4c-1-0.1-0.4-2.6-2.4-0.5c-0.2,1.1,0.5,1,1.1,1c0.6,0,1,0.1,0.2,1.7
                                       c-1.1,0.6-1.7,0.5-2.2,0.7c-0.5,0.2-0.7,0.6-1.3,1.8c-0.7-0.1-0.6-0.8-0.1-1.7c-1,2.2-4,4.5-4.1,6.2c-1.3,0.3-2.8,1.6-4.1,2.7
                                       c-1.3,1-2.5,1.7-3.1,0.7c-0.1,0.4-0.7,1.4-1.5,2.4c-0.8,1-1.8,2.1-2.7,2.7c1-2.2-0.8-1.1-2.9,0.1c-2.1,1.2-4.6,2.5-4.8,1.1
                                       c-0.4,0.9-1.1,1.7-2.1,2.5c-1,0.8-2.2,1.5-3.5,2.3c-2.6,1.5-5.6,2.9-7.8,4.6c0.2-0.3,0.6-0.9,1.1-1c-2.8,1.1-3.9,0.8-5.9,1.1
                                       c0.4,1.9-3.8,4.2-5.7,6.2c-4.2-0.4,1.3-2.9-0.5-4.2c0.8,1.4-5.4,3.8-3.8,4.6c-3.1-0.1-2.5,0.3-4.4-0.7c0.3,2.4-2.4,1.3-4.1,3.5
                                       c-4.3,0.2-1.7-1.8-5.8,0.3C475.5,149.1,479.1,147,479.1,147z M373.6,172.7C373.6,172.7,373.6,172.7,373.6,172.7
                                       c0.9-0.2,1.7-0.3,2.2-0.4C375.3,172.4,374.7,172.5,373.6,172.7z M364.4,173.7c1.1-0.1,2.5-0.2,4-0.3c0.4,0.4,0.1,1-0.8,1
                                       C368,174,365.8,173.9,364.4,173.7z M384.9,181.1c-1.4,0.4-2,0.2-2.2-0.1c0.7-0.1,1.5-0.3,2.5-0.5c0-0.1,0.1-0.1,0.2-0.2
                                       C385.3,180.6,385.2,180.9,384.9,181.1z"/>
                                    <path class="st0" d="M387.3,179c0.2-0.1,0.3-0.1,0.6-0.2c-0.3-0.1-0.6-0.2-0.9-0.2c-0.2,0.2-0.3,0.3-0.5,0.5
                                       C386.8,179,387.1,179,387.3,179z"/>
                                    <path class="st0" d="M402.8,14.7l-0.2-0.1c0.2,0.1,0.3,0.2,0.4,0.3C402.9,14.8,402.9,14.8,402.8,14.7z"/>
                                    <polygon class="st0" points="237.7,173.9 237.7,174.3 238.5,174 	"/>
                                    <path class="st0" d="M451.8,29.2c0.1,0.1,0.2,0.3,0.6,0.5C452.3,29.6,452.1,29.4,451.8,29.2z"/>
                                    <path class="st0" d="M472.7,35.3l2.8,1.4C474.3,36,473.5,35.6,472.7,35.3z"/>
                                    <path class="st0" d="M488.4,41.2c0.3-0.2,0.4-0.3,0.5-0.5c0,0,0,0,0,0L488.4,41.2z"/>
                                    <path class="st0" d="M224.4,15.3c-0.2,0-0.4,0.1-0.6,0.1C222.3,15.7,223.3,15.6,224.4,15.3z"/>
                                    <path class="st0" d="M303.9,10.4c-0.9,0.2-1.7,0.5-2.6,0.6C302.7,11.2,303.8,11.2,303.9,10.4z"/>
                                    <path class="st0" d="M136.9,28C136.9,28,136.9,28,136.9,28c-0.2,0-0.3,0-0.5,0.1L136.9,28z"/>
                                    <path class="st0" d="M297.6,10.9c1.4,0.3,2.6,0.3,3.7,0.1C300.2,10.9,298.8,10.7,297.6,10.9z"/>
                                    <path class="st0" d="M547.7,78.1l0.4,0.6c0.1-0.1,0.2-0.1,0.2-0.1L547.7,78.1z"/>
                                    <path class="st0" d="M548.3,78.6l0.6,0.6C548.7,78.9,548.5,78.6,548.3,78.6z"/>
                                    <path class="st0" d="M550.1,80.5c-0.4-0.4-0.8-0.9-1.2-1.3C549.3,79.7,549.6,80.4,550.1,80.5z"/>
                                    <path class="st0" d="M533,63.1l-0.5,0.3C532.6,63.4,532.8,63.3,533,63.1z"/>
                                    <path class="st0" d="M527.4,59.6c0.6,0.5,1.3,1.2,2.1,2c0.8,0.8,1.6,1.5,2.6,2l0.4-0.2C531.2,63.4,529.2,59.6,527.4,59.6z"/>
                                    <path class="st0" d="M505.2,41.2c1.7,0.9,3.4,1.8,5.1,2.7c-0.7-0.9-1.6-2.1,0.8-1.1C505.2,38.2,512.1,44.4,505.2,41.2z"/>
                                    <path class="st0" d="M488.9,34c3,0.8,0.2-0.7,0.5-1C487.3,32.8,486.8,32.8,488.9,34z"/>
                                    <path class="st0" d="M363.3,8.7l0.4-1.2C361.3,7.4,363.6,7.9,363.3,8.7z"/>
                                    <polygon class="st0" points="338.4,13.3 340.6,14.2 341.8,13.1 	"/>
                                    <polygon class="st0" points="324.5,3.4 325.6,3.3 321.6,3.6 	"/>
                                    <path class="st0" d="M110,21.3l1.1,1.4C112.6,22,112.5,20.2,110,21.3z"/>
                                    <path class="st0" d="M24,58.4c-0.6,0-1.6,0.3-2.5,0.8c-0.9,0.5-1.7,1.3-2.1,2.1C21.1,60.1,23.2,59.2,24,58.4z"/>
                                    <polygon class="st0" points="212.4,184.8 210.5,185.2 214,185 	"/>
                                 </g>
                              </svg>
                           </span>                        
                        </p>
                        <p className="color-white inter-thin ">
                           Na<span className="palavra inter-bold"> produção
                              
                           
                              <svg version="1.1" id="Camada_1" className="linha"   x="0px" y="0px"
                                 viewBox="0 0 562 189.6"  >
                                 <g>
                                    <path class="st0" d="M556.2,95c0.3-0.4,0.1-1.4-0.7-1.2l-0.6,1.7L556.2,95z"/>
                                    <path class="st0" d="M550.8,91.9c-0.9,0.8-2,2.2-1.4,3.7c0.9-1.6,1.2-0.9,2.2-2.8c0.8-0.2,1.4,0.5,1.3,1.1c0.3-0.4,1.2,0.4,1.7-0.7
                                       c-1-1.3-2.4-1.3-3.7-0.3C550.5,92.7,550.7,92.2,550.8,91.9z"/>
                                    <path class="st0" d="M472.7,89.3l0.5,0.2C473.1,89.4,472.9,89.4,472.7,89.3z"/>
                                    <path class="st0" d="M424.3,88.4c0.4,0,0.5-0.2,0.5-0.4C424.5,88.2,424.2,88.3,424.3,88.4z"/>
                                    <path class="st0" d="M416,86.6c0.2,0.1,0.3,0.1,0.5,0.1C416.3,86.6,416.2,86.5,416,86.6z"/>
                                    <path class="st0" d="M472.7,89.3l-1.3-0.5C471.3,89.4,472.1,89.2,472.7,89.3z"/>
                                    <path class="st0" d="M540,95.9c0.3-0.1,0.5-0.2,0.7-0.4c-0.2,0.1-0.4,0.1-0.7,0.2C540.1,95.7,540.1,95.8,540,95.9z"/>
                                    <path class="st0" d="M494,88l0.4,0c0.1-0.2,0.2-0.4,0.3-0.7L494,88z"/>
                                    <path class="st0" d="M510.4,87.7c-0.4-0.1-0.5,1.3-0.6,2c0.2-0.6,0.5-0.9,0.8-1C510.6,88.5,510.5,88.1,510.4,87.7z"/>
                                    <path class="st0" d="M536.4,90.2l0.5-0.2C536.7,90.1,536.5,90.1,536.4,90.2z"/>
                                    <path class="st0" d="M482.3,87.7c0-0.2-0.1-0.4-0.1-0.5C482.2,87.4,482.2,87.5,482.3,87.7z"/>
                                    <path class="st0" d="M24.4,98.3c0-0.1,0-0.2,0-0.2C24.3,98.1,24.3,98.2,24.4,98.3z"/>
                                    <path class="st0" d="M5.4,91.3l0.3-0.1c0-0.3,0.1-0.5,0.1-0.8L5.4,91.3z"/>
                                    <path class="st0" d="M400.9,84.8C400.9,84.8,401,84.8,400.9,84.8C401.4,84.7,401.2,84.7,400.9,84.8z"/>
                                    <path class="st0" d="M262.1,85.8c1.6,1.3-2.6,1.6-0.2,3C261.7,88.3,264.1,86.7,262.1,85.8z"/>
                                    <path class="st0" d="M539.6,95.7c-0.2,0-0.4,0-0.7,0C539.2,95.8,539.4,95.8,539.6,95.7z"/>
                                    <path class="st0" d="M261.9,88.8C261.9,88.9,261.9,88.9,261.9,88.8l0.2,0.1C262.1,88.9,262,88.9,261.9,88.8z"/>
                                    <path class="st0" d="M366.1,85.6c0.1,0.2,0.2,0.4,0.4,0.6C366.4,86.1,366.3,85.9,366.1,85.6z"/>
                                    <path class="st0" d="M392,87.6l-0.6-0.2C391.6,87.6,391.9,87.6,392,87.6z"/>
                                    <path class="st0" d="M352.6,87.6c-0.3-0.1-0.5-0.3-0.9-0.1c0,0.1,0.1,0.1,0.1,0.2C352,87.5,352.2,87.4,352.6,87.6z"/>
                                    <path class="st0" d="M338.6,87.6c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.3-0.1,0.4-0.1L338.6,87.6z"/>
                                    <path class="st0" d="M67,97.6c-0.3-0.4-0.5-0.8-0.6-1.3C66.6,97.3,64.5,97.7,67,97.6z"/>
                                    <path class="st0" d="M540,95.1c-0.1,0.4-0.2,0.5-0.4,0.6c0.2,0,0.3,0,0.5-0.1C540.1,95.4,540.1,95.2,540,95.1z"/>
                                    <path class="st0" d="M352,98.7l-2.2-0.2C350.4,98.6,351.2,98.7,352,98.7z"/>
                                    <path class="st0" d="M167.6,97.4l-0.5,0.2C167.3,97.6,167.5,97.5,167.6,97.4z"/>
                                    <path class="st0" d="M343.2,98.7c-0.4-0.5-0.8-0.8-1.3-1c-0.1,0.1-0.2,0.2-0.3,0.4L343.2,98.7z"/>
                                    <path class="st0" d="M66.4,96.3C66.4,96.3,66.4,96.3,66.4,96.3C66.4,96.3,66.4,96.3,66.4,96.3z"/>
                                    <path class="st0" d="M51.7,99.6c2.1,0,1-1.8,1.4-2.9c2.5-0.5,1.2,1.4,2,2.3c1.6-0.5,2.8-2.2,4-2.1c0.1,0.3,0.3,0.4,0,0.8
                                       c1.7-1.5,5-0.1,6.9-1.9c0.1,0.2,0.2,0.3,0.3,0.5c-0.3-0.9-0.4-1.9-0.3-2.5c1,0,1.8-1.2,2.1,0c-0.6,0-0.6,0.5-0.9,0.8l1-0.5
                                       c0.9,1.7-1.4,0.6-1.4,1.9c1.9-0.6,4.2-2,5.7-1.5c0.3,1.7-3.5,0.6-1.5,2.1l-2.3-0.6c1.8,2.7-1.4-0.2,0.2,2.7c3,0.5,3.4-0.9,6.4-0.4
                                       c0.7-0.9-0.2-1.4,0.5-2.3c4.3-1.7,3.4,4.3,6.6,2c0.8-1.1,1.1-3.3,3.4-2.1l-0.8,1.9c2.9,0.2,1.7-2.8,4.8-1.5
                                       c-0.5,0.8-2.3,0.6-1.9,0.9c2,1.5,1.4-1.1,2.9-1.1l0.3,1.7c3.1-0.3,6.6-1.5,10-1.8c-0.2,0.5-0.8,1,0,1.6c4.4-0.8,9.3,0.2,13.5,1.2
                                       l1.6-1.5c0.4,0,0.7,0.7,0.3,0.9c1.6,0.6,0.1-0.9,0.9-1.5c0.9-0.1,1.2,0.8,0.6,1.3c2.9-1.2,8.3-2.7,11.3-1.1l-0.6,0.4
                                       c2.4,0.6,2.7-3,4.9-1.6l-0.4,0.3c5.2,0.6,10.9-0.7,14.8,1.5c0.7-2.1,5.1,0.9,5.4-2c2.9,1.5,2.1,2,6.3,3c1.1,0.1,1.7-1.8,3-2.1
                                       l0,1.5l1.9-1.6l1,1.8l1.3-0.4c-0.5-0.1-1.1-0.7-1-1.1c2-0.7,4.9,1.1,4.9,1.9l4.8-1.3c-0.1,0.6-0.9,0.8-1.5,1.2
                                       c2.5-1.6,0.5,1.6,2.8,0.2c-0.3-0.3,0.2-0.9,0.2-1.2c1.2,0.9,3.8,0.9,5.7,0.5l-0.4,1.1c3.5,0.3,7.4-0.5,11.4-0.5
                                       c-1.4-0.5-1.1-2.3,0.1-2.5l0.5,1.6c0.7-1-1.2-0.6,0.3-1.8c1.5,0.2,1,1.9,0.1,2.4l1.8-0.6c0.1,0.5-0.3,0.7-0.4,1.4
                                       c0.7-1,3.1,1.6,4.2-0.1l0.1,0.5c4,0,5.7-0.5,9-0.6c0.1-1.4,1.3-1.1,2.3-2.2c2.4,0.4,5,1.6,7.8,1.4c2-0.7,0.6-1.2,1.5-2
                                       c0.1,1.3,3,1.2,1.5,2.1c3.4-0.2,4.4-0.3,8.7-1.3c-0.5-0.5-1-0.8-0.7-1.2c0.8-0.2,1.8,0.5,1.7,1.2l-0.2,0.1
                                       c4.9-1.9,11.2,3.7,15.1,1.1l-0.2,0.1l4-1.4c1.8,0.2-1.1,1,0.3,1.8c4.3-2.1,6.8,0.3,10.4-1.1c2.8,3.8,9.8-0.3,13.5,2.1l-0.2-0.9
                                       c1-0.9,1.2,0.5,1.9,0.7c-0.1-0.7-1-0.8-0.4-1.3c8.2-1.1,16.8,1.3,25.2,2.1c3.1,0.2,1.7-4.6,5.6-2.5l-1.1,1.7
                                       c6.2-2,12.6,0.1,18.7-1.6c-0.4,0.4-0.4,0.9-1,0.9c1.3,1.2,4.7,0.1,4.7-0.9c0.9,0.1,0.2,0.9,0.8,1.4c1.3,0.7,3.2-1.5,3.9-0.3
                                       c0.1,0.2-0.4,0.4-0.6,0.5c0.8-0.6,3.9,0.1,3.1-1.2c2.3,0.7,4.3-0.6,6.1,0.2c1.2-1.5,3.9,0.7,4.3-1.4c-0.2,2.3,4,1.8,5.4,0.8
                                       c-0.5,0.7,1.2,0.7,0.7,1.7c1.6,0,3.1-0.5,2.8-1.6c3,2.7,6.1-2.2,7.8,1.3c4.9-1.2,9.4-2,13.7-2.6c-0.2,2.3-4.8,0.2-4.6,2.6
                                       c4.1-0.9,6.5-2.9,10.6-2.3c1.1,0.3-0.6,1-1,1.4c4.1-0.9,8,0.2,11.7-1c0,0.2-0.4,0.4-0.8,0.6c0.7-0.1,1.7,0.3,1.6-0.7l-0.7,0.3
                                       c-1.2-1.5,2.9-3.1,3-3.9c-1.2,2.9,3.6,0.7,1.7,4.5c0.5-0.2,1.1-0.7,1-1.4c0.7,0.2,0.9,0.6,0.2,1.4c2.6-0.6,1.7-1.6,4-1.6
                                       c0,0.5-0.5,0.7-0.7,1.3c1.2-1,2.4-1.9,4.4-1.5c-0.1,0.3,0.1,0.7-0.5,0.7c3.4-0.1,5.8-2.6,9.6-1.6c-1.1,2.2,1.3,0.7,1.5,2.6l2-0.6
                                       l-0.8-0.9c1.8,0,3.3-1.3,4.9-0.7l-1.3,0.8l3.3-0.4l-1.9-0.7c1.8-1-0.2-2.4,2.2-1.8c-0.5,0.2,1.8,0,2.7,1.1c0,0,0,0,0,0
                                       c0.9-0.6,1.8-1.2,3.1-0.8c0.3,0.6-0.3,2.1-0.1,2.5c0.9-1.6,4.1-0.4,5.2-2.1c-0.4,0.4-0.4,1.4-0.3,1.6l0.7-1.3
                                       c0.8,0.4,0.9,0.6,0.8,1.4c2.5,0.2-0.8-2.4,2.1-1.3c-0.2,0.1-0.1,0.3-0.6,0.5c2.9,0.5,5.7-0.8,8.4-0.2c0.8,1.9-2.4,0.5-1.4,2
                                       c3.9-0.3,8.1-2.3,11.8-1.8l-0.7-0.2c0.5-2.7,2.8,1.3,4.7,0.1c-0.5,0.2-0.6,1.5-0.5,1.2c2.6,0.4,5.3-0.7,9.2-1.5l-0.5,0.7
                                       c1.5,0.6,4,0.4,5.6,0.7c0.5-0.7,1.4-0.6,0.9-1.6c5.1,3.9,9.7-2.1,14.1,1.2c-0.5,0-1.3,0.6-0.9,0.6l3.8-0.6
                                       c-0.3-1.2-0.5-0.7-1.3-1.6c0.5-0.7,1.6-1.2,2.5-1.2c-1.7,0.8,0,1.9,0.8,2.5c-0.1-0.4,0.1-0.7,0.1-1.1c2,2.1,0.3-2.3,3.1-0.9
                                       l-0.1,0.6c1-0.3,2-1.1,3.1-0.9c0.5,1.3-1.1,0.6-1.2,1.7c1.5-0.1,3.1-2.5,4.5-1.2c-0.7,0.4-1.6,0.4-2.3,0.7c1.5,1.6,1.7,1.2,2.8,1.4
                                       l-0.1,0l2.6,0.7L508,95c1.9-3.5,3.9-0.6,6.5-2.9c-2.5,2.2-0.4,1.2-0.6,2.8c0.7,0.4,1.7,0.9,1.4,1.3c1.2-1.2,2.1,1,3.8-0.8
                                       c0.1,0.5,1.3,0,1,1.3c1.6-0.4-0.4-0.8-0.2-1.5c2.5-1.7,5.8,2,9.4-0.3c3.1-0.9,3.6-2.6,6.5-3.4c1,0.8-0.8,1.8-0.8,1.8
                                       c1.5,0.7,2.7,0.6,4.2,0c-0.3,0.4,0,0.8,0.5,1.3c1.2-0.1,0.8-1.8,2.3-1.1c-0.3,0.5-0.6,1.4-1.2,2c1.4-0.4,2.7-1.4,4.2-1.8
                                       c0.4,0.8-0.9,1.3-1.4,1.8c1.6-0.4,4.2-0.8,4.7-2.1l-1-0.5c0.6-0.1,0.3,1.2-0.1,1.4c-1.4,1.1-2-0.5-2.1-1l1-0.3
                                       c-1-2.9-4.1,0.5-6.1,0.3l0.9-2.1l-2.1,1.7c-0.4-0.5-0.8-1.7,0.3-2c-1.5-1-1.3,0.7-2.6-0.4c-0.1-0.2-0.1-0.3,0.1-0.4l-1.4,0.6
                                       c0.3-0.4-0.2-1.5,0.6-1.2c-2.6-1.2-4.1,1.6-5.1,0l0.2-0.1c-3.6,0.7-0.3,1.5-3,3.3l-1.8-3.8l-0.4,1.5c-0.4-0.1-1.1-0.2-1-0.8
                                       c-1.4,1,0.2,0.7-0.6,1.7c-1.8-1.4-4.9,0.7-5.3-1.8c-0.6,1.2,1.7,0.9-0.2,1.7c-0.5-2.6-2.2,0.4-3.8-0.8c0.5-1.3,2-0.6,0.4-1.8
                                       c-1,2.3-3.2-0.5-4.5-0.1c-0.1,1.8-3,0.8-5.3,1.4c0.1-0.6-4.3-1-6.4-1.7l0.1-0.3c-0.4,1.8-2.6,1.5-4.2,1.9c0.1-1.1,0.4-1.5,1.2-2
                                       l-1.5,0c-0.4,0.6-0.8,0.9-1.8,1.1l0.4-1.4c-2,0.1-4,1.6-6.6,0.7c-0.1,1.3,2.1,3.6-0.7,4.4c-0.1-0.7-0.2-1.9,0.5-2.2
                                       c-0.2,0.1-0.8,0.6-1.3,0.3l1.1-1.2c-1.2-0.5-0.6,1-1.6,0.9c0-1-0.7-0.7-0.6-1.5c0.2-0.1,0.8,0.4,1.2,0c-1.1-0.2-2.3-0.8-2.8-1.5
                                       c0.1,0.6,0,1.3-0.8,1.5c-2,0.1-0.7-1.2-1.9-1.7c-0.5,0.7-1.8,0-1.8,1.5c-0.6,0-0.7-0.7-0.9-1.1c-1,0.7-4.8,0.2-3.7,1.8l0.3,0.1
                                       c0,0-0.1,0-0.2,0.1c0,0,0,0,0,0h0c-3.4,1.5-7.1-0.8-10.4-0.2l0-0.5c-2,0.6-4.5,0.3-5.9,1.4c-0.6,0-1.4-0.9-1.2-1.5
                                       c-1.5,0.4-1.7,0.9-3.9,0.1c1.5-0.9-0.5-0.8,1.6-0.6c-2-0.4-2.5-1.2-4.6,0.1c0.7-1.3-1.5-1.6-2.5-1.2l1.4,0.9c-1.1,0.2-2,0.1-3,0
                                       l0.5-1.7c-4.1-2-7.4,3.4-10.5,0.1c-1.1,1.2,2.2,0.8,1.3,2.2c-1.9-0.2-3.8-2.6-4.4-2.6c-2.6-0.9-2.4,2.4-5.1,1.8
                                       c0.3,0.6,0.7,2.2-1.3,2.7c-1.9-0.2-2.3-2.5-1.1-3c0.5,0,0.7,0.1,0.7,0.3c0.6-0.2,1.4-0.5,0.7-1.1l-0.2,0.6
                                       c-0.9-0.6-3.5-0.5-2.9-1.5c-1.4,0.6-0.6,1,0.4,1.3c-2.4-0.9-3.8,0-6.3-0.6c0.4,0.3,0.8,1,0.4,1c-3.6-0.7-1.6,1.1-4.1,1.4
                                       c-1.4-0.9,0.5-2.2-2-1.4c-1.5-0.6-0.3-1.6,0.3-2.1c-2.3,1.1-4.2-0.8-5.5-1l1.2-0.5c-0.7,0.3-1.3,0.3-1.9,0.3l0.7,1.2
                                       c-0.7-0.2-1.3-0.2-1.3-0.7c-0.7,0.8,0.6,2-0.9,3c-0.9-0.6-2.8,0.6-3.2-0.9c2.9,0.5-0.3-1.6,2.2-1.8c-0.5,0.2-1.1,0.2-1.8,0
                                       c-0.1-0.2,0.1-0.3,0.3-0.4c-2.8-1.4-1.3,1.8-4.2,1.7c0.4-0.9-0.7-1.7-1.2-1.5c1.2,0,0.6,1.5-0.5,2.2c-1.9-0.7-2,0.5-3,0.4l1.1,0.3
                                       c-0.4,0.9-1.3,0.8-2.6,1c-0.1-0.7,1.2-0.5,0.7-0.8c-1.4,1.6-3.3-1.6-5.3-0.6c-0.9-0.6-0.1-1.7-0.9-2.1c-2.3,1.2-2.4-1.2-4.8-0.3
                                       c0.7,1.2,0.8,0.9-0.9,2.1l3.9-0.8l-2.5,1.7c1.2,0,2.5-0.8,3.2-0.5c-1.2,1-1.1,0.7-0.5,1.7c-1.6-1.8-3.2,1-5.1-0.2l0.1-3
                                       c-2.8-1.3-5,1.5-8.6,0.8l1.2,0.5c-0.5,1.2-2.2,0.2-3.4,0.6c0.1-1-0.6-1.3-1.1-2c0.1,0.7-2.7-0.1-3,1.9l-1.6-1.3
                                       c-2.9-0.5-3.4,2.8-6.2,2.5c0.9-1.1-0.4-1.8,1.5-2.5c-0.9,0-1.8-0.2-1.8,0.5c-0.6-0.6-2.6,1.3-3.5,0.4c-0.4,0.4-0.5,1.5-1.6,1.4
                                       c-0.1-0.2,0-0.5,0-0.5c0,0.5-1.7,0.7-0.7,1.8c-2.9-3.1-8.1,0.2-10.7-2.4c-2.1,0.3-4.5,0.6-6.4,0.7c0.1-0.3,0-0.5,0.4-0.4
                                       c-2.6-0.9-1.6,2.8-4.5,1.8c-1.5-1.6,1.6-0.9,0.6-1.5c-0.4-3.2-3.5,0.4-5.5-1.5l0.5-0.2c-1.5-1.1-3.7,1.2-6.1,1c0,0,0.1-0.3,0-0.5
                                       c-1.7,1.7-4.9,2.2-7.3,2.7c0.7-1.3,1.1-0.7,0.6-2c-1.1,0.2,0.3,2.1-1.8,2.4c-0.7-0.7-2.2-1.3-2.4-2.7l2-0.1c-1-1.8-2.6,0.1-3.6-0.2
                                       l0.1-0.8c-3.1,0.3-3.1,2.2-6.4,2.1l0.3,0.6c-1.8,1.9-1.5-1.1-3.3,0.4l-0.5-2c-1.2,0.5-4.2,1.9-6.3,2.3c1.2-1.5,3.7-2.2,5.2-3.1
                                       c-1.2,0-4,0.1-4.6,1.1c0.5-0.2,1.1-0.7,1.5-0.4c-1.4,1.6-3.3,2.3-5.7,2.3c0.4-3.8-6.1-1-7.8-3.9c-4.2,1.4-8.2-0.6-13.1-0.4
                                       c1.7,3-1.4,0.1-1.1,3.1c-1.1,0.2-1.6,0.1-1.7-0.1l-3.4-1.8c-0.8-0.9,1.1-0.7,1-1.4c-2.5-0.2-1-1.3-2.7-2.1c0.3,1.1-0.9,1.6-2.3,1.1
                                       l2,1.4c-3.5,1.6-3-2.6-6.5-1l1.1-1c-1.8,0.6-6.6,0-8.3,2.4c-0.3-0.4-0.7-1.2,0.1-1.4c-4.3-0.4-9.5,2.6-12.8,1.1l0.3-0.7
                                       c-0.8,0.2-1.4,1.4-2.4,0.7c0.1-0.3,0.3-0.7,0-0.8c-0.4,0.3-2.1,1.3-3.2,0.9l0.9-0.8c-4.2-1-6.4,2-9.9,2.6c0-2.2-3.3-2.9-4.9-3.3
                                       l0.1-0.3c-4.8-0.3-8.2,1.8-12.7,2.7c-2.8-2.4-9.4-1-13.9-2.3c0.7,0.4-0.1,1.7-0.9,1.9c-1.1-0.4-2.9,1.5-2.7-0.5l0.4,0
                                       c-0.8-2-2.9-1.5-4.6-1.5l-0.3,2.6c-4.4-3.8-11.5,1.4-14-2c-1.1,0.7-2.4,0.7-3.4,1.3l0.1-1.4c-2.9-0.4-4.4-0.5-8.1-0.2l0.7-1
                                       c-2.1-0.1-2.6,4.1-4.5,4.2l-0.6-2.1c-3.6,0-7.2-1.8-10.8,0.1c0.1-0.3,0.2-0.9,0.7-1c-1.7-0.1-6.5-1.4-5.9,1.5
                                       c0-1.9-3.7-0.2-5.6,0.6l0.3-1.4c-1.6,1.8-1.7,2.3-4,2.8c-0.5-0.2-0.5-1.1,0.2-0.8c-1.8-0.7-1.4,1.3-3.6,0.6l0.8-0.9
                                       c-1.9-0.1-1.7,0.4-2.7,1.4c-1.2,0.6-3.5-0.4-3.8-1.6c-0.2,0.8-1.3,1.8-2.4,1.4c-0.1-0.5,0.3-0.8,0.5-0.9c-3.1-0.9-4.6,1.6-6.7,0.3
                                       c0-0.5-0.2-0.7-0.4-0.8c2.5,0,4.4,0.1,4.3,0.2c0-1.3-2-1.5-0.4-3.3c-1.6,1-4.3,3.1-6.3,2.5c-0.1-0.1-0.2-0.2-0.3-0.4l0.2-0.1
                                       c-0.4-0.1-0.8,0-1.2,0.2c-0.6-0.2-1-0.4-1.6-0.4c-0.2-1.1-2.3-0.6-3.3-0.6c0.1,1.4-1.2,1.2,0,2.3c-0.2-0.1,0.2-0.2,0.8-0.2
                                       c0.1,0,0.3-0.1,0.4-0.1c0,0,0,0.1-0.1,0.1c0.7,0,1.5-0.1,2.5-0.1c-0.8,0.7-1.6,1.3-2.7,0.5c-0.5,0.9-1.1,1.8-1.4,2.3
                                       c-1.4-2-4-3-4.3-4.2c-3,0.8-7.1,0.5-9,3.2c-0.9,0.3-1-0.8-1.3-1.2c0.5-0.3,1.1-0.2,1.4-0.6c-1.7,0.7-5.1,0.3-5.5,2.1
                                       c-2.2-0.8,1.2-1.7-1.8-1.4l0.4-0.6c-5.9-2.9-9.5,4.7-16,0.9c0.5,0.2,0.7,0.3,0.8,0.6c-7.4-1.7-15.3,1.6-22.6-1.1
                                       c-2.1-0.5-2.3,0.6-3.6,1.5L32.1,88l-2.2,1.7c-3.1,1.3-5.3-3.7-9.2-1.3l0.5,1c-1.4-0.2-4.4,0.6-4.2-0.6c-0.1,0.2-0.5,1.1-1,0.5
                                       l0-0.8L12.5,90c-2.7-0.6,0.4-3.1-3.5-3.3C7.6,87,4.9,89,5.5,89.5C6,89.6,6.9,90,7,90.8l-1.3,0.4c-0.2,2.3-1.3,4.7,2,6.3l2.3-1.4
                                       l0.7,1.1l-1.9,0.1c1.1,1.1,1.8,0.1,2.5-0.5c0.2,0.6,0.5,1,0.9,1.2l2.2-2.2c0.3,0.9-1,1.8,0.5,2.3c1-1-1-2,0.7-2.7
                                       c1.1,1.1,0.8,1.9,2,1c0.2,0.6-0.1,1-0.6,1.2c1.1-0.7,3-0.3,3.6-1.5c1.1,1.3,3.8-0.3,3.7,1.9c0.4-0.5,2-1.7,3-1.8
                                       c-1.9,2.6,3.9,0.2,3.4,3c1.4-2.4,5.1-1.1,7.6-2.4c-0.1,0.2-0.4,0.6-0.6,0.4c1.4,0.2,1.8,0.9,2.7,1.4c0.2-2,2.4-2.4,3.5-3.6
                                       c1.7,1.9-1,2.3-0.5,4.1c-0.1-1.6,3-1.6,2.4-2.9c1.3,1.2,1.1,0.6,1.8,2.2c0.2-2.5,1.2-0.4,2.3-1.9c1.9,1.2,0.5,2.3,2.6,1.5
                                       C53.5,98.8,51.7,99.6,51.7,99.6z M101.3,95.4C101.3,95.4,101.3,95.4,101.3,95.4c-0.4,0.1-0.8,0.1-1,0.1
                                       C100.5,95.5,100.8,95.5,101.3,95.4z M105.5,95.4c-0.5-0.1-1.1-0.1-1.8-0.1c-0.1-0.5,0-1,0.4-1C103.8,94.7,104.8,95,105.5,95.4z
                                       M96.6,85.6c0.7-0.2,0.9,0.1,1,0.4c-0.3,0-0.7,0.1-1.2,0.2c0,0.1-0.1,0.1-0.1,0.2C96.4,86.1,96.5,85.8,96.6,85.6z"/>
                                    <path class="st0" d="M95.4,87.5c-0.1,0-0.1,0.1-0.3,0.1c0.1,0.1,0.3,0.2,0.4,0.3c0.1-0.1,0.2-0.3,0.2-0.4
                                       C95.7,87.5,95.6,87.5,95.4,87.5z"/>
                                    <path class="st0" d="M473.3,89.6L473.3,89.6c0,0,0.1,0.1,0.1,0.2C473.3,89.7,473.3,89.6,473.3,89.6z"/>
                                    <polygon class="st0" points="163,98.1 163,97.7 162.6,98 	"/>
                                    <path class="st0" d="M496.2,94.4c0,0.1,0.1,0.3,0.3,0.4C496.5,94.7,496.4,94.5,496.2,94.4z"/>
                                    <path class="st0" d="M506.2,94.7l1.4,0.5C507,94.9,506.5,94.8,506.2,94.7z"/>
                                    <path class="st0" d="M513.8,95.4c0.1-0.2,0.1-0.4,0.1-0.6c0,0,0,0,0,0L513.8,95.4z"/>
                                    <path class="st0" d="M392.6,97.1c-0.1,0-0.2,0-0.3,0.1C391.6,97.4,392,97.3,392.6,97.1z"/>
                                    <path class="st0" d="M428.6,93.8c-0.4,0.3-0.8,0.5-1.2,0.7C428.1,94.5,428.6,94.6,428.6,93.8z"/>
                                    <path class="st0" d="M352.3,98.7C352.3,98.7,352.3,98.7,352.3,98.7c-0.1,0-0.2,0-0.2,0L352.3,98.7z"/>
                                    <path class="st0" d="M425.8,94.4c0.6,0.3,1.2,0.2,1.7,0C426.9,94.3,426.3,94.2,425.8,94.4z"/>
                                    <path class="st0" d="M546.8,96l0.4,0.1c0-0.1,0-0.2,0-0.2L546.8,96z"/>
                                    <path class="st0" d="M547.2,95.9l0.4-0.2C547.5,95.7,547.3,95.7,547.2,95.9z"/>
                                    <path class="st0" d="M548.5,95.4l-0.9,0.4C547.9,95.7,548.3,95.8,548.5,95.4z"/>
                                    <path class="st0" d="M536.7,95.4l-0.1,0.5C536.7,95.8,536.7,95.6,536.7,95.4z"/>
                                    <path class="st0" d="M533.7,95.5c0.7,0.1,1.8,1.1,2.9,0.8l0.1-0.4C536.1,96.7,534.4,94.7,533.7,95.5z"/>
                                    <path class="st0" d="M520.9,89.2l2.6,0.4c-0.4-0.5-1-1.3,0.1-1.4C520.3,86.6,524.2,89.5,520.9,89.2z"/>
                                    <path class="st0" d="M512.9,88.4c1.4-0.3,0-0.8,0.1-1.1C512.1,87.8,511.9,88,512.9,88.4z"/>
                                    <path class="st0" d="M455.3,88.4l0.1-1.3C454.4,87.3,455.4,87.7,455.3,88.4z"/>
                                    <polygon class="st0" points="444.3,95.1 445.3,95.8 445.8,94.7 	"/>
                                    <polygon class="st0" points="437.8,85.9 438.3,85.8 436.5,86.2 	"/>
                                    <path class="st0" d="M341.1,86.4l0.3,1.6C342.1,87.7,342.3,85.9,341.1,86.4z"/>
                                    <path class="st0" d="M299.3,88c-0.5-0.8-1.6-1.3-2.4-0.5C297.9,87.6,298.8,88.2,299.3,88z"/>
                                    <polygon class="st0" points="174.1,85.4 174.9,84.9 173.3,85.3 	"/>
                                 </g>
                              </svg>
                           </span> animal.
                           
                        </p>
                     </div>
                     <div className="desc">
                        <p className="color-white inter-light opacity">Cursos intensivos em áreas específicas da produção animal, <br></br>com as mentes mais brilhantes de cada setor. 
                        </p>
                     </div>
                     <div className="desc">
                        <p className="green inter-light ">Clique na sua área para ver cursos disponíveis. 
                        </p>
                     </div>
                     <div className="botao">
                        <button onClick={() => scrollTo('#section-2')} className="btn inter-regular"> <img src={icone1} alt=""/></button>
                        <button onClick={() => scrollTo('#section-3')} className="btn inter-regular"><img src={icone2} alt=""/></button>
                        <button onClick={() => scrollTo('#section-4')} className="btn inter-regular"><img src={icone3} alt=""/></button>
                        <button onClick={() => scrollTo('#section-6')} className="btn inter-regular"><img src={icone4} alt=""/></button>
                        <button onClick={() => scrollTo('#section-5')} className="btn inter-regular"><img src={icone5} alt=""/></button>
                        <button onClick={() => scrollTo('#section-7')} className="btn inter-regular"><img className="coelho" src={icone6} alt=""/></button>
                        <button onClick={() => scrollTo('#section-8')} className="btn inter-regular"><img  src={icone7} alt=""/></button>


                     </div>
                  </div>
               </div>
               {/* 
               <div className="right-col">
                  <img src={fundocol} alt="" />
               </div>
               */}
            </div>
            {/* <div className="empresas">
                        <p className=" margin-zero inter-light text-center">Empresas que já participaram em cursos FarmIN - Livestock Trainings. 
                        </p>
                     </div> */}
            {/* <div className="wrapper-3">
               <div className="col1">
                  <img src={marca1} alt="" />
               </div>
               <div className="col1">
                  <img src={marca2} alt="" />
               </div>
               <div className="col1">
                  <img src={marca3} alt="" />
               </div>
               <div className="col1">
                  <img src={marca4} alt="" />
               </div>
               <div className="col1">
                  <img src={marca5} alt="" />
               </div>
            </div> */}
            <div className="scroll-mobile inter-light">
    <span class="scroll-icon">
      <span class="scroll-icon__wheel-outer">
        <span class="scroll-icon__wheel-inner"></span>
      </span>
    </span>
    <p>Scroll</p>
  </div>
			</div>
         
			</div>
         <div className="scroll inter-light">
    <span class="scroll-icon">
      <span class="scroll-icon__wheel-outer">
        <span class="scroll-icon__wheel-inner"></span>
      </span>
    </span>
    <p>Scroll</p>
  </div>
			<div className="rect-black">
            <div className="wrapper-section">
               <div className="rect">
			   <div className="col1">
				   <div className="rec-peq">
				   <AccessibilityIcon/>
			   <div className="value inter-light">
				   Business Network
			   </div>
				   </div>

			 
			   </div>
			   <div className="col2">
			   <div className="rec-peq">
			   <BubbleChartIcon/>
			   <div className="value inter-light">
				   Scientific Content
			   </div>
			   </div>
				</div>
				<div className="col3">
				<div className="rec-peq">
				<EqualizerIcon/>
			   <div className="value inter-light">
				   Results Discussion
			   </div>
			   </div>
				</div>
               </div>
            </div>
         </div>
         </div>
        
      </div>
   </div>
 

   <div id="section-2">
	   <div className="bovinos-carne">
		   <div className="wrapper">
		   <div className="title inter-bold">
			   	Bovinos de Carne

			</div>
			{/* <div className="desc inter-regular">
			   	Cursos intensivos FarmIN com especialização e relação direta com a indústria dos bovinos de carne.

			</div> */}
		   </div>
		   <div className="wrapper-cards">
            
         <div className="coluna">
			   	<div className="card">
                {/* <div className="esgotado">
                <img className="esgotadoimg" src={esgotado} alt="" />

                </div> */}
                  <div className="body">
                  <div className="data">
                 16 e 17 de Janeiro de 2025
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Qualidade na carne de bovino
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal22} alt="" />
                  <div className="nome inter-bold">
                     Humberto Rocha
                  </div>
                  <div className="titulo inter-regular">
                  Prof. Universitário e Consultor em Qualidade e Processamento da carne
                  </div>

                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal37} alt="" />
                  <div className="nome inter-bold">
                     Mauro Soares
                  </div>
                  <div className="titulo inter-regular">
                    Category Manager - SONAE MC
                  </div>

                  </div>
                  </div>
                  {/* <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal29} alt="" />
                  <div className="nome inter-bold">
                     Chef Samuel Barros
                  </div>
                  <div className="titulo inter-regular">
                  BBQ and Fire Specialist
                  </div>

                  </div>
                  </div> */}
              
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/qualidade-de-carne/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div>


       

            <div className="coluna">
			   	<div className="card">
                {/* <div className="esgotado">
                <img className="esgotadoimg" src={esgotado} alt="" />

                </div> */}
                  <div className="body">
                  <div className="data">
                28 e 29 de Março de 2025
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Gestão reprodutiva e ecografia bovina avançada
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal7} alt="" />
                  <div className="nome inter-bold">
                     Rui D'Orey Branco
                  </div>
                  <div className="titulo inter-regular">
                  Prof. Universitário, PhD em fisiologia reprodutiva, consultor especializado em reprodução e bem-estar de bovinos
                  </div>

                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal15} alt="" />
                  <div className="nome inter-bold">
                    Pedro Meireles
                  </div>
                  <div className="titulo inter-regular">
                  Consultor especialista em
fertilidade e gestão de unidades
de produção leiteira
                  </div>

                  </div>
                  </div>
                  {/* <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal29} alt="" />
                  <div className="nome inter-bold">
                     Chef Samuel Barros
                  </div>
                  <div className="titulo inter-regular">
                  BBQ and Fire Specialist
                  </div>

                  </div>
                  </div> */}
              
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/gestao-reprodutiva-e-ecografia-bovina/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div>
            <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  24, 25, 26 e 27 de Fevereiro de 2025
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Curso de Auditores - Welfare Quality em bovinos de carne
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal6} alt="" />
                  <div className="nome inter-bold">
                     George Stilwell
                  </div>
                  <div className="titulo inter-regular">
                  Prof. Universitário e Consultor especializado em clínica e bem-estar de ruminantes
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/WQ-bovinos-carne/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> 
           
            </div>
			   {/* <div className="col">
			   	<div className="card">
                
                  <div className="body">
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                       Masterclass
                    </div>
                  <div className="curso inter-regular">
                  Como construir uma vacada funcional, eficiente e sustentável
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal1} alt="" />
                  <div className="nome inter-bold">
                     PJ Budler
                  </div>
                  <div className="titulo inter-regular">
                    Consultor e juiz de gado
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <button className="btn inter-regular">Ver Curso</button>

                     </div>
                  </div>

				</div>
			   </div>
			   */}



        

            {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data">
                  10, 11 e 12 de Novembro de 2022
                  </div>
                  <div className="wrapper-body">
                
                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
            Podologia e Aparo de Cascos
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal11} alt="" />
                <div className="nome inter-bold">
                Richard Touret
                </div>
                <div className="titulo inter-regular">
                  Médico Veterinário e Consultor <br></br>
                </div>
               
                

                  
           
                 

                </div>
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/podologia-aparo-cascos/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}
           {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data">
                  08, 09 e 10 de Outubro, 2021
                  </div>
                  <div className="wrapper-body">
                
                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Ecografia e palpação retal em bovinos
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal7} alt="" />
                <div className="nome inter-bold">
                Rui d’Orey Branco 
                </div>
                <div className="titulo inter-regular">
                  Consultor <br></br>
                </div>
               
                

                  
           
                 

                </div>
                
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal15} alt="" />
                <div className="nome inter-bold">
                Pedro Meireles
                </div>
                <div className="titulo inter-regular">
                  Consultor <br></br>
                </div>
               
                

                  
           
                 

                </div>
                
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/ecografia-palpacao/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}

            {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data">
                  12, 13 e 14 de Novembro, 2021
                  </div>
                  <div className="wrapper-body">
                
                  <div className="masterclass inter-bold">
                  Curso Intensivo | TURMA 2
                  </div>
                <div className="curso inter-regular">
                Ecografia e palpação retal em bovinos
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal7} alt="" />
                <div className="nome inter-bold">
                Rui d’Orey Branco 
                </div>
                <div className="titulo inter-regular">
                  Consultor <br></br>
                </div>
               
                

                  
           
                 

                </div>
                
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal15} alt="" />
                <div className="nome inter-bold">
                Pedro Meireles
                </div>
                <div className="titulo inter-regular">
                  Consultor <br></br>
                </div>
               
                

                  
           
                 

                </div>
                
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/ecografia-palpacao-turma2/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}
            {/* <div className="coluna">
            
               <div className="card">
                
                <div className="body">
                  <div className="wrapper-body">

                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Curso de auditores Welfare Quality: Matadouro bovinos
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal2} alt="" />
                <div className="nome inter-bold">
                   Antoni Dalmau Bueno
                </div>
                <div className="titulo inter-regular">
                  Professor e Consultor IRTA <br></br>
                </div>
               
                

                  
           
                 

                </div>
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/WQ-matadouro-bovinos/">
                   <button className="btn inter-regular">Ver Curso</button> </Link>

                   </div>
                </div>

          </div>
         
			   </div> */}



			   {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                  <div className="wrapper-body">

                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Qualidade na produção de carne de bovino: BEEFexperience
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal4} alt="" />
                <div className="nome inter-bold">
                   Phill Bass
                </div>
                <div className="titulo inter-regular">
                  Consultor, cientista e professor
                </div>

                </div>
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <button className="btn inter-regular">Ver Curso</button>

                   </div>
                </div>

          </div>
			   </div> */}
             

           
           
          
            {/* <div className="coluna">
            <div className="card color-white align-center nome inter-bold">

            Brevemente disponíveis
            </div>
            </div> */}
			
		
   
		 

         {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data">
                  4 e 5 de Novembro de 2022
                  </div>
                  <div className="wrapper-body">
                
                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
            Gestão, Saúde e Nutrição de Feedlots Bovinos
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal23} alt="" />
                <div className="nome inter-bold">
                Luís Barciaga
                </div>
                <div className="titulo inter-regular">
                Feedlot Nutrition and Production Consultant <br></br>
                </div>
                </div>
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/gestao-saude-nutricao-feedlots-bovinos/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}
            
            {/* <div className="coluna">
            <div className="card borda-gold">
                
                <div className="body">
                <div className="data">
                  07, 08 e 09 de Junho de 2022
                  </div>
                  <div className="wrapper-body">
                
                  <div className="masterclass inter-bold">
                     Evento
                  </div>
                <div className="curso inter-regular">
            Temple Grandin Show - Behavior and management in cattle production
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal17} alt="" />
                <div className="nome inter-bold">
                Temple Grandin
                </div>
                <div className="titulo inter-regular">
                  International Consultant 
                  <br></br>
                </div>
               
                

                  
           
                 

                </div>
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   
                   <a href="https://templegrandinshow.com/" target="_blanc">
                   <button className="btn inter-regular cor-gold">Evento do Ano </button>
                  </a>
                   </div>
                </div>

          </div>
			   </div> */}
      
         
            {/* </div> */}
            <div className="wrapper-cards">

            {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                 28 e 29 de Setembro de 2023
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                     Seleção genética em linha pura e cruzamento industrial
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal43} alt="" />
                  <div className="nome inter-bold">
                     Eng. Pepe Pestalardo
                  </div>
                  <div className="titulo inter-regular">
                 Engenheiro de produção agropecuária
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/selecao-genetica/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}

         

            {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  22, 23 e 24 de Novembro de 2023
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                     Curso prático de aspiração folicular (OPU) em bovinos
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal36} alt="" />
                  <div className="nome inter-bold">
                     Dr. André Dayan
                  </div>
                  <div className="titulo inter-regular">
                 Médico veterinário pós-graduado em biotecnologia da reprodução animal
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/aspiracao-folicular/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}


{/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  21 de Maio de 2024
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">

Manejo y gestión de ganadería de bajo coste. <br></br> El manejo como mayor determinante de rentabilidad
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal49} alt="" />
                  <div className="nome inter-bold">
                  Pablo Etcheberry
                  </div>
                  <div className="titulo inter-regular">
                  Produtor ganadero y consultor
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/gestao-ganadaria/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}


         


         
<div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  2,3 e 4 de abril de 2025
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                    Gestão, liderança e sucessão no agronegócio
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal46} alt="" />
                  <div className="nome inter-bold">
                     Miguel Cavalcanti
                  </div>
                  <div className="titulo inter-regular">
                 CEO do Beepoint e Agrotalento
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/gestao-lideranca/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div>
            <div className="coluna">
            <div className="card">
            {/* <div className="esgotado">
                <img className="esgotadoimg" src={brevemente} alt="" />

                </div> */}
                <div className="body">
                <div className="data">
                 6, 7 y 8 de Marzo, 2025
                  </div>
                  <div className="wrapper-body">

                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Agricultura regenerativa y maneio holístico
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal53} alt="" />
                <div className="nome inter-bold">
                   Meghan Sapp
                </div>
                <div className="titulo inter-regular">
                Especialista en agricultura regenerativa
                </div>

                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal54} alt="" />
                <div className="nome inter-bold">
                   Allan Savory
                </div>
                <div className="titulo inter-regular">
                Biólogo, autor, defensor de la agricultura regenerativa
                </div>

                </div>
                </div>
                <div className="btn-div">
                <div className="wrapper-btn">
      <Link to="/agricultura-regenerativa-y-pastos/">

      <button className="btn inter-regular">Ver Curso</button>
      </Link>
      </div>
                
                   {/* <div className="wrapper-btn">
                 
                   <button className="btn inter-regular" onClick={openPopup}>Mais informações</button>
                  

                   </div> */}
                </div>

          </div>
			   </div>
            <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data">

                {/* <span id="second-app-title" style={{ borderBottom: "2px solid #02f3a7" }}>
              Brevemente
                </span> */}
                <span id="second-app-title">
              Maio 2025
                </span>

                  
                  </div>
                  {/* <ReactTooltip
        anchorId="second-app-title"
        place="bottom"
        variant="info"
        content="Este curso não é continuo, verifique o programa detalhado."
      /> */}
                  <div className="wrapper-body">
                
                  <div className="masterclass inter-bold">
                  Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Formação de Agentes de Inseminação Artificial em bovinos
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal7} alt="" />
                <div className="nome inter-bold">
                Rui d’Orey Branco 
                </div>
                <div className="titulo inter-regular">
                Prof. Universitário, PhD em fisiologia reprodutiva, consultor especializado em reprodução e bem-estar de bovinos <br></br>
                </div>
               
                

                  
           
                 

                </div>
                
                </div>
                
           
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/IA/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div>


           


          
            {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data">
                08 de Outubro de 2024
                  </div>
                  <div className="wrapper-body">
                
                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Maneio, comportamento e bem-estar em vacadas de carne
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                      
                <img className="imagem" src={pal6} alt="" />
                <div className="nome inter-bold">
               George Stilwell
                </div>
                <div className="titulo inter-regular">
                  Professor e consultor BEA em bovinos<br></br>
                </div>
  
                </div>
                </div>
             
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/maneio-comportamento-vacadas/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}
         
      
        
            </div>
            <div className="wrapper-cards">

            <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data">
                 17 e 18 de Julho, 2025
                  </div>
                  <div className="wrapper-body">

                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Pecuária Nespresso: Qualidade de carne de bovino
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal32} alt="" />
                <div className="nome inter-bold">
                   Roberto Barcellos
                </div>
                <div className="titulo inter-regular">
                  Consultor e especialista em marcas de carne
                </div>

                </div>
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/pecuaria-nespresso/">
                   <button className="btn inter-regular">Ver Curso</button>
                   </Link>

                   </div>
                </div>

          </div>
			   </div>

            <div className="coluna">
            <div className="card">
            <div className="esgotado">
                <img className="esgotadoimg" src={brevemente} alt="" />

                </div>
                <div className="body">
                <div className="data">
                 Brevemente
                  </div>
                  <div className="wrapper-body">

                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Descorna de vitelos para produtores
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal52} alt="" />
                <div className="nome inter-bold">
                   {/* Roberto Barcellos */}
                </div>
                <div className="titulo inter-regular">
                  {/* Consultor e especialista em marcas de carne */}
                </div>

                </div>
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                 
                   <button className="btn inter-regular" onClick={openPopup}>Mais informações</button>
                  

                   </div>
                </div>

          </div>
			   </div>
          
            {showPopup && (
        <div className="popup-overlay-2">
          <div className="popup-content-2">
            <p>Se tem interesse neste curso, envie-nos email para <a href="mailto:farmin@farmin-trainings.net">farmin@farmin-trainings.net</a> e entraremos em contacto com mais informações.</p>
            <button onClick={closePopup} className="btn-close-popup">Fechar</button>
          </div>
        </div>
      )}

            {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data">
                04 de Outubro de 2024
                  </div>
                  <div className="wrapper-body">
                
                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Antibioterapia baseada em evidências em bovinos
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                      
                <img className="imagem" src={pal48} alt="" />
                <div className="nome inter-bold">
               Ricardo Bexiga
                </div>
                <div className="titulo inter-regular">
                  Especialista em ruminantes <br></br>
                </div>
  
                </div>
                </div>
             
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/antibioterapia-bovinos/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}


         

            {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  09 e 10 de Outubro de 2024
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                     Curso prático de transferência de embriões em bovinos
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal35} alt="" />
                  <div className="nome inter-bold">
                     Daniel Martinez
                  </div>
                  <div className="titulo inter-regular">
                 Especialista em tecnologias de reprodução assistida em bovinos
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/transferencia-embrioes/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}
         
            {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  28 de Novembro de 2024
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                    Marketing e diferenciação
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal46} alt="" />
                  <div className="nome inter-bold">
                     Miguel Cavalcanti
                  </div>
                  <div className="titulo inter-regular">
                  Consultor de marketing e diferenciação
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/marketing-agronegocio/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}
            {/* <div className="coluna">

              
            <div className="card">
            <div className="esgotado">
                <img className="esgotadoimg" src={esgotado} alt="" />

                </div>
                <div className="body">
                <div className="data">
                  18 e 19 de Nov. de 2022
                  </div>
                  <div className="wrapper-body">
                
                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Pastoreio total e seleção da vacada a erva - Turma 1
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal28} alt="" />
                <div className="nome inter-bold">
                Eng. Jaime Elizondo Braun
                </div>
                <div className="titulo inter-regular">
                Consultor e produtor de bovinos <br></br>
                </div>
                </div>
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/pastoreio-maneio-extensivo-turma1/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}

            {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data">
                  24 e 25 de Novembro de 2022
                  </div>
                  <div className="wrapper-body">
                
                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Pastoreio total e seleção da vacada a erva - Turma 2
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal28} alt="" />
                <div className="nome inter-bold">
                Eng. Jaime Elizondo Braun
                </div>
                <div className="titulo inter-regular">
                Consultor e produtor de bovinos <br></br>
                </div>
                </div>
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/pastoreio-maneio-extensivo-turma2/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}
            
           
            

            
{/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  2 e 3 de Novembro de 2023
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                    Gestão ambiental em explorações de bovinos
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal42} alt="" />
                  <div className="nome inter-bold">
                     Prof. David Fangueiro
                  </div>
                  <div className="titulo inter-regular">
                 Especialista em Gestão de Resíduos Orgânicos Agrícolas
                  </div>

                  </div>
                  </div> */}

                  {/* <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal41} alt="" />
                  <div className="nome inter-bold">
                     Eng. Ramon Gea
                  </div>
                  <div className="titulo inter-regular">
                 Especialista em gestão ambiental - SEGALÉS
                  </div>

                  </div>
                  </div> */}

                  {/* <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal40} alt="" />
                  <div className="nome inter-bold">
                     Eng. Felix Freixer
                  </div>
                  <div className="titulo inter-regular">
                 Especialista em gestão ambiental - SEGALÉS
                  </div>

                  </div>
                  </div> */}

                  {/* <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal39} alt="" />
                  <div className="nome inter-bold">
                     Eng. Mikel Zubicaray
                  </div>
                  <div className="titulo inter-regular">
                 Especialista em gestão ambiental - SEGALÉS
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/gestao-ambiental-bovinos/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}
      

         
            {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data">
                25 de outubro de 2024
                  </div>
                  <div className="wrapper-body">
                
                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Qualidade na produção de forragens
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                      
                <img className="imagem" src={pal20} alt="" />
                <div className="nome inter-bold">
                Luís Queirós
                </div>
                <div className="titulo inter-regular">
                  Especialista em Forragens <br></br>
                </div>
  
                </div>
                </div>
             
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/agronomia-forragens/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}
            {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data">
                24 e 25 de outubro, 2024
                  </div>
                  <div className="wrapper-body">
                
                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Nutrição prática de bovinos leiteiros
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                      
                <img className="imagem" src={pal20} alt="" />
                <div className="nome inter-bold">
                Luís Queirós
                </div>
                <div className="titulo inter-regular">
                  Especialista em Forragens <br></br>
                </div>
  
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal21} alt="" />
                <div className="nome inter-bold">
                Luís Figueiredo
                </div>
                <div className="titulo inter-regular">
                  Consultor em gestão técnica e económica de efetivos leiteiros<br></br>
                </div>
                </div>
                
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/nutricao-bovinos-leiteiros/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}
         
           
            </div>
		
			 
		   
		  
	   </div>
   </div>

   <div id="section-3">
	   <div className="bovinos-carne">
		   <div className="wrapper">
		   <div className="title inter-bold">
			   	Bovinos de Leite

			</div>
			{/* <div className="desc inter-regular">
			   	Cursos intensivos FarmIN com especialização e relação direta com a indústria dos bovinos de leite.

			</div> */}
		   </div>
		   <div className="wrapper-cards">
         
     


          
{/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  11 e 12 de Dezembro de 2023
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                     Diagnóstico clínico avançado em bovinos
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal44} alt="" />
                  <div className="nome inter-bold">
                     Dr. Angel Abuelo
                  </div>
                  <div className="titulo inter-regular">
                 Médico veterinário
                  </div>

                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal45} alt="" />
                  <div className="nome inter-bold">
                     Dr. Bart Pardon
                  </div>
                  <div className="titulo inter-regular">
                 Médico veterinário 
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/diagnostico-clinico/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}


           
            
            {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data-black">
               24 e 25 de outubro de 2024
                  </div>
                  <div className="wrapper-body">
                
                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Gestão e maneio alimentar de bovinos leiteiros
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                      
                <img className="imagem" src={pal20} alt="" />
                <div className="nome inter-bold">
                Luís Queirós
                </div>
                <div className="titulo inter-regular">
                  Especialista em Forragens <br></br>
                </div>
  
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal21} alt="" />
                <div className="nome inter-bold">
                Luís Figueiredo
                </div>
                <div className="titulo inter-regular">
                  Consultor em gestão técnica e económica de efetivos leiteiros<br></br>
                </div>
                </div>
                
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/nutricao-bovinos-leiteiros/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}
            <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  2,3 e 4 de abril de 2025
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                    Gestão, liderança e sucessão no agronegócio
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal46} alt="" />
                  <div className="nome inter-bold">
                     Miguel Cavalcanti
                  </div>
                  <div className="titulo inter-regular">
                 CEO do Beepoint e Agrotalento
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/gestao-lideranca/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div>
            <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data-black">
                  4,5,6 e 7 de Fevereiro de 2025
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Curso de Auditores - Welfare Quality em bovinos de leite
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal6} alt="" />
                  <div className="nome inter-bold">
                     George Stilwell
                  </div>
                  <div className="titulo inter-regular">
                  Prof. Universitário e Consultor especializado em clínica e bem-estar de ruminantes
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/WQ-bovinos-leite/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div>
            <div className="coluna">
			   	<div className="card">
                {/* <div className="esgotado">
                <img className="esgotadoimg" src={esgotado} alt="" />

                </div> */}
                  <div className="body">
                  <div className="data">
                28 e 29 de Março de 2025
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Gestão reprodutiva e ecografia bovina avançada
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal7} alt="" />
                  <div className="nome inter-bold">
                     Rui D'Orey Branco
                  </div>
                  <div className="titulo inter-regular">
                  Prof. Universitário, PhD em fisiologia reprodutiva, consultor especializado em reprodução e bem-estar de bovinos
                  </div>

                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal15} alt="" />
                  <div className="nome inter-bold">
                    Pedro Meireles
                  </div>
                  <div className="titulo inter-regular">
                  Consultor especialista em
fertilidade e gestão de unidades
de produção leiteira
                  </div>

                  </div>
                  </div>
                  {/* <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal29} alt="" />
                  <div className="nome inter-bold">
                     Chef Samuel Barros
                  </div>
                  <div className="titulo inter-regular">
                  BBQ and Fire Specialist
                  </div>

                  </div>
                  </div> */}
              
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/gestao-reprodutiva-e-ecografia-bovina/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div>
 {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data">

                <span id="second-app-title" style={{ borderBottom: "2px solid #02f3a7" }}>
               Junho/Julho (verificar programa detalhado)
                </span>

                  
                  </div>
                  <ReactTooltip
        anchorId="second-app-title"
        place="bottom"
        variant="info"
        content="Este curso não é continuo, verifique o programa detalhado."
      />
                  <div className="wrapper-body">
                
                  <div className="masterclass inter-bold">
                  Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Formação de Agentes de Inseminação Artificial em bovinos
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal7} alt="" />
                <div className="nome inter-bold">
                Rui d’Orey Branco 
                </div>
                <div className="titulo inter-regular">
                  Consultor <br></br>
                </div>
               
                

                  
           
                 

                </div>
                
                </div> */}
                
                {/* <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal14} alt="" />
                <div className="nome inter-bold">
                Nestor Chagas e Silva
                </div>
                <div className="titulo inter-regular">
                  Professor e Consultor <br></br>
                </div>
               
                

                  
           
                 

                </div>
                
                </div> */}
                {/* <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/IA/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}
			  

            {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data">
                  11, 12, 13, 17, 18, 19, 20, 25, 26 e 27 de Janeiro 2023
                  </div>
                  <div className="wrapper-body">
                
                  <div className="masterclass inter-bold">
                  Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Formação de Agentes de Inseminação Artificial em bovinos
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal7} alt="" />
                <div className="nome inter-bold">
                Rui d’Orey Branco 
                </div>
                <div className="titulo inter-regular">
                  Consultor <br></br>
                </div>
               
                

                  
           
                 

                </div>
                
                </div> */}
                
                {/* <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal14} alt="" />
                <div className="nome inter-bold">
                Nestor Chagas e Silva
                </div>
                <div className="titulo inter-regular">
                  Professor e Consultor <br></br>
                </div>
               
                

                  
           
                 

                </div>
                
                </div> */}
                {/* <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/IA/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}
            
			  
           
           {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data-black">
                08, 09 e 10 de Outubro, 2021
                  </div>
                  <div className="wrapper-body">
                
                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Ecografia e palpação retal em bovinos
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal7} alt="" />
                <div className="nome inter-bold">
                Rui d’Orey Branco 
                </div>
                <div className="titulo inter-regular">
                  Consultor <br></br>
                </div>
               
                

                  
           
                 

                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal15} alt="" />
                <div className="nome inter-bold">
                Pedro Meireles
                </div>
                <div className="titulo inter-regular">
                  Consultor <br></br>
                </div>
               
                

                  
           
                 

                </div>
                
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/ecografia-palpacao/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}

            {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data-black">
                12, 13 e 14 de Novembro, 2021
                  </div>
                  <div className="wrapper-body">
                
                  <div className="masterclass inter-bold">
                  Curso Intensivo | Turma 2
                  </div>
                <div className="curso inter-regular">
                Ecografia e palpação retal em bovinos
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal7} alt="" />
                <div className="nome inter-bold">
                Rui d’Orey Branco 
                </div>
                <div className="titulo inter-regular">
                  Consultor <br></br>
                </div>
               
                

                  
           
                 

                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal15} alt="" />
                <div className="nome inter-bold">
                Pedro Meireles
                </div>
                <div className="titulo inter-regular">
                  Consultor <br></br>
                </div>
               
                

                  
           
                 

                </div>
                
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/ecografia-palpacao-turma2/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}
          

			 {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data-black">
                20, 21 e 22 de Outubro de 2022

                  </div>
                  <div className="wrapper-body">
                
                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
            Podologia e Aparo de Cascos
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal11} alt="" />
                <div className="nome inter-bold">
                Richard Touret
                </div>
                <div className="titulo inter-regular">
                  Médico Veterinário e Consultor <br></br>
                </div>
               
                

                  
           
                 

                </div>
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/podologia-aparo-cascos/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div>

            <div className="coluna">
            <div className="card borda-gold ">
                
                <div className="body">
                <div className="data-black">
                  07, 08 e 09 de Junho de 2022
                  </div>
                  <div className="wrapper-body">
                
                  <div className="masterclass inter-bold">
                     Evento do Ano
                  </div>
                <div className="curso inter-regular">
            Temple Grandin Show - Behavior and management in cattle production
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal17} alt="" />
                <div className="nome inter-bold">
                Temple Grandin
                </div>
                <div className="titulo inter-regular">
                  International Consultant 
                  <br></br>
                </div>
               
                

                  
           
                 

                </div>
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   
                   <a href="https://templegrandinshow.com/" target="_blanc">
                   <button className="btn inter-regular cor-gold">Evento do Ano</button>
                  </a>
                   </div>
                </div>

          </div>
			   </div> */}



            {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data-black">
                21 de junho de 2024
                  </div>
                  <div className="wrapper-body">
                
                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Qualidade na produção de forragens
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                      
                <img className="imagem" src={pal20} alt="" />
                <div className="nome inter-bold">
                Luís Queirós
                </div>
                <div className="titulo inter-regular">
                  Especialista em Forragens <br></br>
                </div>
  
                </div>
                </div>
             
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/agronomia-forragens/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}


		   </div>

         <div className="wrapper-cards">
         <div className="coluna">
            <div className="card">
            {/* <div className="esgotado">
                <img className="esgotadoimg" src={brevemente} alt="" />

                </div> */}
                <div className="body">
                <div className="data">
                 6, 7 y 8 de Marzo, 2025
                  </div>
                  <div className="wrapper-body">

                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Agricultura regenerativa y maneio holístico
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal53} alt="" />
                <div className="nome inter-bold">
                   Meghan Sapp
                </div>
                <div className="titulo inter-regular">
                Especialista en agricultura regenerativa
                </div>

                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal54} alt="" />
                <div className="nome inter-bold">
                   Allan Savory
                </div>
                <div className="titulo inter-regular">
                Biólogo, autor, defensor de la agricultura regenerativa
                </div>

                </div>
                </div>
                <div className="btn-div">
                <div className="wrapper-btn">
      <Link to="/agricultura-regenerativa-y-pastos/">

      <button className="btn inter-regular">Ver Curso</button>
      </Link>
      </div>
                
                   {/* <div className="wrapper-btn">
                 
                   <button className="btn inter-regular" onClick={openPopup}>Mais informações</button>
                  

                   </div> */}
                </div>

          </div>
			   </div>
         <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data-black">
                  29 e 30 de abril, 2025
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Decisiones económicas en las granjas lecheras
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal51} alt="" />
                  <div className="nome inter-bold">
                     Victor Cabrera
                  </div>
                  <div className="titulo inter-regular">
                    Professor e especialista em gestão de explorações de leite
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/decidiones-economicas-granjas/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div>

<div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data-black">
                 Maio 2025
                  </div>
                  <div className="wrapper-body">

                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Formação de Agentes de Inseminação Artificial em bovinos
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal7} alt="" />
                <div className="nome inter-bold">
                Rui d’Orey Branco 
                </div>
                <div className="titulo inter-regular">
                Prof. Universitário, PhD em fisiologia reprodutiva, consultor especializado em reprodução e bem-estar de bovinos <br></br>
                </div>
               
                

                  
           
                 

                </div>
                </div>
                {/* <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal14} alt="" />
                <div className="nome inter-bold">
                Nestor Chagas e Silva
                </div>
                <div className="titulo inter-regular">
                  Professor e Consultor <br></br>
                </div>
               
                

                  
           
                 

                </div>
                
                </div> */}
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/IA/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div>

         
         {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data-black">
                04 de Outubro de 2024
                  </div>
                  <div className="wrapper-body">
                
                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Antibioterapia baseada em evidências em bovinos
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                      
                <img className="imagem" src={pal48} alt="" />
                <div className="nome inter-bold">
               Prof. Ricardo Bexiga
                </div>
                <div className="titulo inter-regular">
                  Especialista em ruminantes <br></br>
                </div>
  
                </div>
                </div>
             
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/antibioterapia-bovinos/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}
         
            {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  28 e Novembro de 2024
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Marketing e diferenciação
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal46} alt="" />
                  <div className="nome inter-bold">
                     Miguel Cavalcanti
                  </div>
                  <div className="titulo inter-regular">
                  Consultor de marketing e diferenciação
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/marketing-agronegocio/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}
         {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  22, 23 e 24 de Novembro de 2023
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                     Curso prático em aspiração folicular (OPU) em bovinos
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal36} alt="" />
                  <div className="nome inter-bold">
                     Dr. André Dayan
                  </div>
                  <div className="titulo inter-regular">
                 Médico veterinário pós-graduado em biotecnologia da reprodução animal
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/aspiracao-folicular/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}
         {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  2 e 3 de Novembro de 2023
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                    Gestão ambiental em explorações de bovinos
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal42} alt="" />
                  <div className="nome inter-bold">
                     Prof. David Fangueiro
                  </div>
                  <div className="titulo inter-regular">
                 Especialista em Gestão de Resíduos Orgânicos Agrícolas
                  </div>

                  </div>
                  </div> */}

                  {/* <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal41} alt="" />
                  <div className="nome inter-bold">
                     Eng. Ramon Gea
                  </div>
                  <div className="titulo inter-regular">
                 Especialista em gestão ambiental - SEGALÉS
                  </div>

                  </div>
                  </div> */}

                  {/* <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal40} alt="" />
                  <div className="nome inter-bold">
                     Eng. Felix Freixer
                  </div>
                  <div className="titulo inter-regular">
                 Especialista em gestão ambiental - SEGALÉS
                  </div>

                  </div>
                  </div> */}

                  {/* <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal39} alt="" />
                  <div className="nome inter-bold">
                     Eng. Mikel Zubicaray
                  </div>
                  <div className="titulo inter-regular">
                 Especialista em gestão ambiental - SEGALÉS
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/gestao-ambiental-bovinos/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}

              {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  16 e 17 de Novembro de 2023
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                     Curso prático de transferência de embriões em bovinos
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal35} alt="" />
                  <div className="nome inter-bold">
                     Daniel Martinez
                  </div>
                  <div className="titulo inter-regular">
                 Especialista em tecnologias de reprodução assistida em bovinos
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/transferencia-embrioes/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}
           
        
         

            
         


			
			  
		
			 
		   </div>
		  
	   </div>
   </div>
   <div id="section-6">
	   <div className="bovinos-carne">
		   <div className="wrapper">
		   <div className="title inter-bold">
			   	Suínos

		</div>
			{/* <div className="desc inter-regular">
			   	Cursos intensivos FarmIN com especialização e relação direta com a indústria dos bovinos de leite.

			</div> */}
		   </div>
		   <div className="wrapper-cards">

         {/* <p class="inter-regular white">Neste momento não existem cursos disponíveis.</p> */}
         {/* <div className="coluna">

<div className="card">
 
   <div className="body">
   <div className="data">
   21 e 22 de Maio, 2024
   </div>
     <div className="wrapper-body">

     <div className="masterclass inter-bold">
        Masterclass
     </div>
   <div className="curso inter-regular">
   Curso de auditores Welfare Quality - Engorda de Suínos
   </div>
   </div>
   </div>

   <div className="header">
   <div className="wrapper-header">
   <img className="imagem" src={pal26} alt="" />
   <div className="nome inter-bold">
   Rui Cordeiro
   </div>
   <div className="titulo inter-regular">
   Professor e especialista em suínos
   </div>

   </div>
   </div>
   <div className="btn-div">
      <div className="wrapper-btn">
      <Link to="/WQ-engorda-suinos/">

      <button className="btn inter-regular">Ver Curso</button>
      </Link>
      </div>
   </div>

</div>

</div> */}
        

         {/* <div className="coluna">

<div className="card">
 
   <div className="body">
   <div className="data">
   22 de Fevereiro, 2023
   </div>
     <div className="wrapper-body">

     <div className="masterclass inter-bold">
        Masterclass
     </div>
   <div className="curso inter-regular">
   Qualidade da Água - Peça fundamental para produção suína
   </div>
   </div>
   </div>
   <div className="header">
   <div className="wrapper-header">
   <img className="imagem" src={pal27} alt="" />
   <div className="nome inter-bold">
   Dr. Pedro Jose Hernandez de la Cruz
   </div>
   <div className="titulo inter-regular">
   Veterinário especialista em suínos - Vall Companys
   </div>

   </div>
   </div>

   <div className="btn-div">
      <div className="wrapper-btn">
      <Link to="/qualidade-agua-producao-suina/">

      <button className="btn inter-regular">Ver Curso</button>
      </Link>
      </div>
   </div>

</div>

</div> */}

			   

            {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  23 e 24 de Abril de 2024
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                       Masterclass
                    </div>
                  <div className="curso inter-regular">
                  Curso de auditores Welfare Quality - Porcas reprodutoras e leitões
                  </div>
                  </div>
                  </div>
               
               
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal26} alt="" />
                  <div className="nome inter-bold">
                  Rui Cordeiro
                  </div>
                  <div className="titulo inter-regular">
                  Professor e especialista em suínos
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/WQ-porcas-reprodutoras-leitoes/">
                     <button className="btn inter-regular">Ver Curso</button>
</Link>
                     </div>
                  </div>

				</div>
			   </div> */}

            <div className="coluna">
			   	<div className="card">
                  {/* <div className="esgotado">
                <img className="esgotadoimg" src={reagendamento} alt="" />

                </div> */}
                  <div className="body">
                  <div className="data">
                 13 e 14 de Janeiro, 2025
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                     Necropsias porcinas y atlas de lesiones
       
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal47} alt="" />
                  <div className="nome inter-bold">
                     Prof. Joaquim Segalés
                  </div>
                  <div className="titulo inter-regular">
                 Professor e especialista em suínos
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/necropsia-suinos/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div>
            <div className="coluna">
            <div className="card">
            {/* <div className="esgotado">
                <img className="esgotadoimg" src={brevemente} alt="" />

                </div> */}
                <div className="body">
                <div className="data">
                 6, 7 y 8 de Marzo, 2025
                  </div>
                  <div className="wrapper-body">

                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Agricultura regenerativa y maneio holístico
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal53} alt="" />
                <div className="nome inter-bold">
                   Meghan Sapp
                </div>
                <div className="titulo inter-regular">
                Especialista en agricultura regenerativa
                </div>

                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal54} alt="" />
                <div className="nome inter-bold">
                   Allan Savory
                </div>
                <div className="titulo inter-regular">
                Biólogo, autor, defensor de la agricultura regenerativa
                </div>

                </div>
                </div>
                <div className="btn-div">
                <div className="wrapper-btn">
      <Link to="/agricultura-regenerativa-y-pastos/">

      <button className="btn inter-regular">Ver Curso</button>
      </Link>
      </div>
                
                   {/* <div className="wrapper-btn">
                 
                   <button className="btn inter-regular" onClick={openPopup}>Mais informações</button>
                  

                   </div> */}
                </div>

          </div>
			   </div>

            {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                 <div className="data">
                  14, 15 e 16 de Setembro, 2021
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Curso de auditores Welfare Quality - Matadouro Suínos
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal3} alt="" />
                  <div className="nome inter-bold">
                    Quim Pallisera Lloveras
                  </div>
                  <div className="titulo inter-regular">
                    Consultor IRTA
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/WQ-matadouro-suinos/">

                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}
			  
           
						  
			 
		   </div>
		  
	   </div>
   </div>


   
   <div id="section-5">
	   <div className="bovinos-carne">
		   <div className="wrapper">
		   <div className="title inter-bold">
			   	Pequenos Ruminantes

			</div>
			{/* <div className="desc inter-regular">
			   	Neste momento não existem cursos disponíveis nesta área.

			</div> */}
		   </div>
		   <div className="wrapper-cards">
         <div className="coluna">
            <div className="card">
            {/* <div className="esgotado">
                <img className="esgotadoimg" src={brevemente} alt="" />

                </div> */}
                <div className="body">
                <div className="data">
                 6, 7 y 8 de Marzo, 2025
                  </div>
                  <div className="wrapper-body">

                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Agricultura regenerativa y maneio holístico
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal53} alt="" />
                <div className="nome inter-bold">
                   Meghan Sapp
                </div>
                <div className="titulo inter-regular">
                Especialista en agricultura regenerativa
                </div>

                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal54} alt="" />
                <div className="nome inter-bold">
                   Allan Savory
                </div>
                <div className="titulo inter-regular">
                Biólogo, autor, defensor de la agricultura regenerativa
                </div>

                </div>
                </div>
                <div className="btn-div">
                <div className="wrapper-btn">
      <Link to="/agricultura-regenerativa-y-pastos/">

      <button className="btn inter-regular">Ver Curso</button>
      </Link>
      </div>
                
                   {/* <div className="wrapper-btn">
                 
                   <button className="btn inter-regular" onClick={openPopup}>Mais informações</button>
                  

                   </div> */}
                </div>

          </div>
			   </div>
         <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  2,3 e 4 de abril de 2025
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                    Gestão, liderança e sucessão no agronegócio
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal46} alt="" />
                  <div className="nome inter-bold">
                     Miguel Cavalcanti
                  </div>
                  <div className="titulo inter-regular">
                 CEO do Beepoint e Agrotalento
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/gestao-lideranca/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div>

            <div className="coluna">
            <div className="card">
            <div className="esgotado">
                <img className="esgotadoimg" src={brevemente} alt="" />

                </div>
                <div className="body">
                <div className="data">
                 Brevemente
                  </div>
                  <div className="wrapper-body">

                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
               Inseminação artificial em pequenos ruminantes
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal52} alt="" />
                <div className="nome inter-bold">
                   {/* Roberto Barcellos */}
                </div>
                <div className="titulo inter-regular">
                  {/* Consultor e especialista em marcas de carne */}
                </div>

                </div>
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                 
                   <button className="btn inter-regular" onClick={openPopup}>Mais informações</button>
                  

                   </div>
                </div>

          </div>
			   </div>

            {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  01 de Fevereiro de 2024
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Marketing e diferenciação
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal46} alt="" />
                  <div className="nome inter-bold">
                     Miguel Cavalcanti
                  </div>
                  <div className="titulo inter-regular">
                  Consultor de marketing e diferenciação
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/marketing-agronegocio/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}
   {/* <div className="coluna">
			   	<div className="card">
               
                  <div className="body">
                  <div className="data-black">
                  10 de Dezembro, 2024
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Maneio do rebanho ovino - Reprodução, nutrição e sanidade
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal24} alt="" />
                  <div className="nome inter-bold">
                    José Sainz
                  </div>
                  <div className="titulo inter-regular">
                    Consultor de rebanhos de pequenos ruminantes
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/maneio-ovino/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}

			   {/* <div className="coluna">
			   	<div className="card">
               
                  <div className="body">
                  <div className="data-black">
                  09, 10 e 11 de Fevereiro, 2022
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Auditor de bem-estar em pequenos ruminantes leiteiros
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal6} alt="" />
                  <div className="nome inter-bold">
                    George Stilwell
                  </div>
                  <div className="titulo inter-regular">
                    Professor Universitário e Investigador WQ
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/WQ-ovelhas-cabras-leite/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}

            {/* <div className="coluna">
			   	<div className="card">
               
                  <div className="body">
                  <div className="data">
                  15, 16, 22 e 23 de Janeiro de 2022  
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Inseminação Artificial e Ciclo Reprodutivo em Pequenos Ruminantes
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal14} alt="" />
                  <div className="nome inter-bold">
                    João Nestor Chagas e Silva
                  </div>
                  <div className="titulo inter-regular">
                    Professor e Consultor de reprodução em ruminantes
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/IA-pequenos-ruminantes/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}
			  
            {/* <div className="coluna">
			   	<div className="card">
               
                  <div className="body">
                  <div className="data">
                 5, 6, 19 e 20 de Março de 2022  
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo | Turma 2
                    </div>
                  <div className="curso inter-regular">
                  Inseminação Artificial e Ciclo Reprodutivo em Pequenos Ruminantes
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal14} alt="" />
                  <div className="nome inter-bold">
                    João Nestor Chagas e Silva
                  </div>
                  <div className="titulo inter-regular">
                    Professor e Consultor de reprodução em ruminantes
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/IA-pequenos-ruminantes-turma2/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}

            {/* <div className="coluna">
			   	<div className="card">
               
                  <div className="body">
                  <div className="data">
                  24, 25 e 26 de Janeiro de 2023  
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Formação de auditor de bem-estar em matadouro de pequenos ruminantes
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal3} alt="" />
                  <div className="nome inter-bold">
                  Joaquim  Pallisera Lloveras 
                  </div>
                  <div className="titulo inter-regular">
                  Consultor IRTA
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/WQ-matadouro-pequenos-ruminantes/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}
            {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data">
                  26 e 27 de Janeiro de 2023
                  </div>
                  <div className="wrapper-body">
                
                  <div className="masterclass inter-bold">
                  Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Gestão e saúde de engordas de borregos
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal24} alt="" />
                <div className="nome inter-bold">
                Dr. José Maria Gonzalez Sainz
                </div>
                <div className="titulo inter-regular">
                  Consultor de engordas de borregos <br></br>
                </div>
               
                

                  
           
                 

                </div>
                
                </div>
                
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal25} alt="" />
                <div className="nome inter-bold">
                Dr. José Maria Bello Dronda
                </div>
                <div className="titulo inter-regular">
                  Consultor nutricionista de engordas de borregos <br></br>
                </div>
               
                

                  
           
                 

                </div>
                
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/gestao-engordas-borregos/">

                   <button className="btn inter-regular">Ver Curso</button>
                  </Link>
                   </div>
                </div>

          </div>
			   </div> */}
           
						  
			 
		   </div>
		  
	   </div>
   </div>





   <div id="section-8">
	   <div className="bovinos-carne">
		   <div className="wrapper">
		   <div className="title inter-bold">
			   	Agro

		</div>
			{/* <div className="desc inter-regular">
			   	Cursos intensivos FarmIN com especialização e relação direta com a indústria dos bovinos de leite.

			</div> */}
		   </div>
		   <div className="wrapper-cards">
         <div className="coluna">
            <div className="card">
            {/* <div className="esgotado">
                <img className="esgotadoimg" src={brevemente} alt="" />

                </div> */}
                <div className="body">
                <div className="data">
                 6, 7 y 8 de Marzo, 2025
                  </div>
                  <div className="wrapper-body">

                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Agricultura regenerativa y maneio holístico
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal53} alt="" />
                <div className="nome inter-bold">
                   Meghan Sapp
                </div>
                <div className="titulo inter-regular">
                Especialista en agricultura regenerativa
                </div>

                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal54} alt="" />
                <div className="nome inter-bold">
                   Allan Savory
                </div>
                <div className="titulo inter-regular">
                Biólogo, autor, defensor de la agricultura regenerativa
                </div>

                </div>
                </div>
                <div className="btn-div">
                <div className="wrapper-btn">
      <Link to="/agricultura-regenerativa-y-pastos/">

      <button className="btn inter-regular">Ver Curso</button>
      </Link>
      </div>
                
                   {/* <div className="wrapper-btn">
                 
                   <button className="btn inter-regular" onClick={openPopup}>Mais informações</button>
                  

                   </div> */}
                </div>

          </div>
			   </div>
         {/* <p class="inter-regular white">Neste momento não existem cursos disponíveis.</p> */}
         {/* <div className="coluna">

<div className="card">
 
   <div className="body">
   <div className="data">
   21 e 22 de Maio, 2024
   </div>
     <div className="wrapper-body">

     <div className="masterclass inter-bold">
        Masterclass
     </div>
   <div className="curso inter-regular">
   Curso de auditores Welfare Quality - Engorda de Suínos
   </div>
   </div>
   </div>

   <div className="header">
   <div className="wrapper-header">
   <img className="imagem" src={pal26} alt="" />
   <div className="nome inter-bold">
   Rui Cordeiro
   </div>
   <div className="titulo inter-regular">
   Professor e especialista em suínos
   </div>

   </div>
   </div>
   <div className="btn-div">
      <div className="wrapper-btn">
      <Link to="/WQ-engorda-suinos/">

      <button className="btn inter-regular">Ver Curso</button>
      </Link>
      </div>
   </div>

</div>

</div> */}
        

         {/* <div className="coluna">

<div className="card">
 
   <div className="body">
   <div className="data">
   22 de Fevereiro, 2023
   </div>
     <div className="wrapper-body">

     <div className="masterclass inter-bold">
        Masterclass
     </div>
   <div className="curso inter-regular">
   Qualidade da Água - Peça fundamental para produção suína
   </div>
   </div>
   </div>
   <div className="header">
   <div className="wrapper-header">
   <img className="imagem" src={pal27} alt="" />
   <div className="nome inter-bold">
   Dr. Pedro Jose Hernandez de la Cruz
   </div>
   <div className="titulo inter-regular">
   Veterinário especialista em suínos - Vall Companys
   </div>

   </div>
   </div>

   <div className="btn-div">
      <div className="wrapper-btn">
      <Link to="/qualidade-agua-producao-suina/">

      <button className="btn inter-regular">Ver Curso</button>
      </Link>
      </div>
   </div>

</div>

</div> */}

			   

            {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  23 e 24 de Abril de 2024
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                       Masterclass
                    </div>
                  <div className="curso inter-regular">
                  Curso de auditores Welfare Quality - Porcas reprodutoras e leitões
                  </div>
                  </div>
                  </div>
               
               
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal26} alt="" />
                  <div className="nome inter-bold">
                  Rui Cordeiro
                  </div>
                  <div className="titulo inter-regular">
                  Professor e especialista em suínos
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/WQ-porcas-reprodutoras-leitoes/">
                     <button className="btn inter-regular">Ver Curso</button>
</Link>
                     </div>
                  </div>

				</div>
			   </div> */}

            {/* <div className="coluna">
			   	<div className="card">
                  <div className="esgotado">
                <img className="esgotadoimg" src={reagendamento} alt="" />

                </div>
                  <div className="body">
                  <div className="data">
                  11 de Outubro de 2024
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                     Necropsia de suínos e atlas de lesões
       
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal47} alt="" />
                  <div className="nome inter-bold">
                     Joaquim Segalés
                  </div>
                  <div className="titulo inter-regular">
                 Professor e especialista em suínos
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/necropsia-suinos/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}

            {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                 <div className="data">
                  14, 15 e 16 de Setembro, 2021
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Curso de auditores Welfare Quality - Matadouro Suínos
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal3} alt="" />
                  <div className="nome inter-bold">
                    Quim Pallisera Lloveras
                  </div>
                  <div className="titulo inter-regular">
                    Consultor IRTA
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/WQ-matadouro-suinos/">

                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}
			  
           
						  
			 
		   </div>
		  
	   </div>
   </div>




   <div id="section-4">
	   <div className="bovinos-carne">
		   <div className="wrapper">
		   <div className="title inter-bold">
			   	Aves

			</div>
         {/* <div className="desc inter-regular">
			   	Neste momento não existem cursos disponíveis nesta área.

			</div> */}
		   </div>
		   <div className="wrapper-cards">
            {/* <p class="inter-regular white">Neste momento não existem cursos disponíveis.</p> */}
         {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  14, 15, 16 e 17 de Dezembro, 2021
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Auditor de bem-estar em galinhas poedeiras
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal18} alt="" />
                  <div className="nome inter-bold">
                  Dra. Aida Xercavins Simó
                  </div>
                  <div className="titulo inter-regular">
                    Técnica de investigação bem-estar animal IRTA
                  </div>

                  </div>
                  </div>

            
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/WQ-galinhas-poedeiras/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}

            {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  25, 26, 27 e 28 de Janeiro, 2022
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo - TURMA 2
                    </div>
                  <div className="curso inter-regular">
                  Auditor de bem-estar em galinhas poedeiras
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal18} alt="" />
                  <div className="nome inter-bold">
                  Dra. Aida Xercavins Simó
                  </div>
                  <div className="titulo inter-regular">
                    Técnica de investigação bem-estar animal IRTA
                  </div>

                  </div>
                  </div>

            
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/WQ-galinhas-poedeiras-turma-2/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}
  {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  28 de novembro de 2024
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Marketing e diferenciação
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal46} alt="" />
                  <div className="nome inter-bold">
                     Miguel Cavalcanti
                  </div>
                  <div className="titulo inter-regular">
                  Consultor de marketing e diferenciação
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/marketing-agronegocio/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}
     <div className="coluna">
            <div className="card">
            {/* <div className="esgotado">
                <img className="esgotadoimg" src={brevemente} alt="" />

                </div> */}
                <div className="body">
                <div className="data">
                 6, 7 y 8 de Marzo, 2025
                  </div>
                  <div className="wrapper-body">

                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Agricultura regenerativa y maneio holístico
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal53} alt="" />
                <div className="nome inter-bold">
                   Meghan Sapp
                </div>
                <div className="titulo inter-regular">
                Especialista en agricultura regenerativa
                </div>

                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal54} alt="" />
                <div className="nome inter-bold">
                   Allan Savory
                </div>
                <div className="titulo inter-regular">
                Biólogo, autor, defensor de la agricultura regenerativa
                </div>

                </div>
                </div>
                <div className="btn-div">
                <div className="wrapper-btn">
      <Link to="/agricultura-regenerativa-y-pastos/">

      <button className="btn inter-regular">Ver Curso</button>
      </Link>
      </div>
                
                   {/* <div className="wrapper-btn">
                 
                   <button className="btn inter-regular" onClick={openPopup}>Mais informações</button>
                  

                   </div> */}
                </div>

          </div>
			   </div>
              <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  28 e 29 de Maio de 2025
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Calidad en el procesamiento de aves
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal16} alt="" />
                  <div className="nome inter-bold">
                  Fábio Nunes
                  </div>
                  <div className="titulo inter-regular">
                    Consultor e engenheiro químico
                  </div>

                  </div>
                  </div>

            
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/calidad-procesamiento-aves/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div>
<div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  2,3 e 4 de abril de 2025
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                    Gestão, liderança e sucessão no agronegócio
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal46} alt="" />
                  <div className="nome inter-bold">
                     Miguel Cavalcanti
                  </div>
                  <div className="titulo inter-regular">
                 CEO do Beepoint e Agrotalento
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/gestao-lideranca/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div>
{/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  12 de Dezembro, 2024
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Biosegurança em avicultura de A a Z
                  </div>
                  </div>
                  </div>
           

                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal34} alt="" />
                  <div className="nome inter-bold">
                     Salvatore Catania
                  </div>
                  <div className="titulo inter-regular">
                  Professor e consultor especialista em Biosegurança e Mycoplasma
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/bioseguranca-avicultura/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}
{/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  13 e 14 de Julho, 2023
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Auditor Interno em Bem-estar de galinhas poedeiras
                  </div>
                  </div>
                  </div>
           

                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal33} alt="" />
                  <div className="nome inter-bold">
                     Diogo Gama
                  </div>
                  <div className="titulo inter-regular">
                    Auditor certificado WFQ e AWIN
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/auditor-interno-wfq-galinhas-poedeiras/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}
			   {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  19 e 20 de Abril, 2023
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Qualidade no processamento avícola
                  </div>
                  </div>
                  </div> */}
                  {/* <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal9} alt="" />
                  <div className="nome inter-bold">
                     Marcos Sanchez-Plata
                  </div>
                  <div className="titulo inter-regular">
                    Professor Texas Tech University
                  </div>

                  </div>
                  </div> */}

                  {/* <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal16} alt="" />
                  <div className="nome inter-bold">
                     Fábio Nunes
                  </div>
                  <div className="titulo inter-regular">
                    Consultor e Engenheiro Químico
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/EPA/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}
			  
           
           
            {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data">
                  30 e 31 de Janeiro e 1 de Fevereiro, 2023
                  </div>
                  <div className="wrapper-body">

                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Auditor de bem-estar em exploração de perus
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal13} alt="" />
                <div className="nome inter-bold">
                Immaculada Estevez
                </div>
                <div className="titulo inter-regular">
                Consultora, Investigadora e Especialista - NEIKER <br></br>
                </div>
                </div>
                </div>

                <div className="header">
                <div className="wrapper-header">   
                <img className="imagem" src={pal8} alt="" />
                <div className="nome inter-bold">
                Xavier Averós
                </div>
                <div className="titulo inter-regular">
                Veterinário, Investigador e Especialista - NEIKER <br></br>
                </div>
               
                </div>
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/WQ-perus/">
                   <button className="btn inter-regular">Ver Curso</button>
                   </Link>
                   </div>
                </div>

          </div>
			   </div> */}

 {/* <div className="coluna">
            <div className="card">
                
                <div className="body">
                <div className="data">
                  09 de Março, 2023
                  </div>
                  <div className="wrapper-body">

                 <div className="masterclass inter-bold">
                    BEST PRACTICE HENS
                  </div>
                <div className="curso inter-regular">
                Economia, implicações e maneio na transição para produção sem gaiolas
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                

                  
                <img className="imagem" src={pal31} alt="" />
                <div className="nome inter-bold">
                Bas Rodenburg
                </div>
                <div className="titulo inter-regular">
                Especialista em maneio de Poedeiras
 <br></br>
                </div>
                </div>
                </div>

                <div className="header">
                <div className="wrapper-header">   
                <img className="imagem" src={pal30} alt="" />
                <div className="nome inter-bold">
                Peter van Horne
                </div>
                <div className="titulo inter-regular">
                Economista Avicola<br></br>
                </div>
               
                

                  
           
                 

                </div>
                </div>
                <div className="btn-div">
                   <div className="wrapper-btn">
                   <Link to="/best-practice-hens/">
                   <button className="btn inter-regular">Ver Curso</button>
                   </Link>
                   </div>
                </div>

          </div>
			   </div> */}

			
			  
		
			 
		   </div>
		  
	   </div>
   </div>
  
   <div id="section-7">
	   <div className="bovinos-carne">
		   <div className="wrapper">
		   <div className="title inter-bold">
			   	Coelhos

			</div>
			{/* <div className="desc inter-regular">
			   	Neste momento não existem cursos disponíveis nesta área.

			</div> */}
		   </div>
		   <div className="wrapper-cards">
         {/* <p class="inter-regular black">Neste momento não existem cursos disponíveis.</p> */}

			   {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data-black">
                  10 e 11 de Janeiro, 2023
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Curso Avançado de Necropsias e Urgências em Coelhos
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal19} alt="" />
                  <div className="nome inter-bold">
                    Samuel Boucher
                  </div>
                  <div className="titulo inter-regular">
                    Consultor Especialista em Cunicultura
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/necropsias-urgencias-coelhos/">
                     
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}

             <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data-black">
                  18 e 19 de Fevereiro, 2025
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Gestión ambiental y ventilación en cunicultura
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal50} alt="" />
                  <div className="nome inter-bold">
                    François Menini
                  </div>
                  <div className="titulo inter-regular">
                    Nutricionista e especialista em cunicultura
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/gestao-ambiental-ventilacao-cunicultura/">
                     
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div>
			  
            <div className="coluna">
            <div className="card">
            {/* <div className="esgotado">
                <img className="esgotadoimg" src={brevemente} alt="" />

                </div> */}
                <div className="body">
                <div className="data">
                 6, 7 y 8 de Marzo, 2025
                  </div>
                  <div className="wrapper-body">

                 <div className="masterclass inter-bold">
                    Curso Intensivo
                  </div>
                <div className="curso inter-regular">
                Agricultura regenerativa y maneio holístico
                </div>
                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal53} alt="" />
                <div className="nome inter-bold">
                   Meghan Sapp
                </div>
                <div className="titulo inter-regular">
                Especialista en agricultura regenerativa
                </div>

                </div>
                </div>
                <div className="header">
                <div className="wrapper-header">
                <img className="imagem" src={pal54} alt="" />
                <div className="nome inter-bold">
                   Allan Savory
                </div>
                <div className="titulo inter-regular">
                Biólogo, autor, defensor de la agricultura regenerativa
                </div>

                </div>
                </div>
                <div className="btn-div">
                <div className="wrapper-btn">
      <Link to="/agricultura-regenerativa-y-pastos/">

      <button className="btn inter-regular">Ver Curso</button>
      </Link>
      </div>
                
                   {/* <div className="wrapper-btn">
                 
                   <button className="btn inter-regular" onClick={openPopup}>Mais informações</button>
                  

                   </div> */}
                </div>

          </div>
			   </div>

           	 {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data-black">
                  31 de Janeiro, 2024
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Patologias comuns na cunicultura e necrópsias de campo
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal38} alt="" />
                  <div className="nome inter-bold">
                    Dr. Manuel Monteiro
                  </div>
                  <div className="titulo inter-regular">
                    Consultor e professor
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/patologias-cunicultura/">
                     
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div>  */}


                  <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  2,3 e 4 de abril de 2025
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                    Gestão, liderança e sucessão no agronegócio
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal46} alt="" />
                  <div className="nome inter-bold">
                     Miguel Cavalcanti
                  </div>
                  <div className="titulo inter-regular">
                 CEO do Beepoint e Agrotalento
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/gestao-lideranca/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div>  

            {/* <div className="coluna">
			   	<div className="card">
                
                  <div className="body">
                  <div className="data">
                  01 de Fevereiro de 2024
                  </div>
                    <div className="wrapper-body">

                    <div className="masterclass inter-bold">
                    Curso Intensivo
                    </div>
                  <div className="curso inter-regular">
                  Marketing e diferenciação
                  </div>
                  </div>
                  </div>
                  <div className="header">
                  <div className="wrapper-header">
                  <img className="imagem" src={pal46} alt="" />
                  <div className="nome inter-bold">
                     Miguel Cavalcanti
                  </div>
                  <div className="titulo inter-regular">
                  Consultor de marketing e diferenciação
                  </div>

                  </div>
                  </div>
                  <div className="btn-div">
                     <div className="wrapper-btn">
                     <Link to="/marketing-agronegocio/">
                     <button className="btn inter-regular">Ver Curso</button>
                     </Link>
                     </div>
                  </div>

				</div>
			   </div> */}
			 
		   </div>
		  
	   </div>
   </div>

   
   <div id="past-courses-section">
      <div className="section-name inter-bold">Cursos passados</div>
      <div className="section-desc inter-regular opacity">Temos o orgulho de ter marcado a diferença na formação dos nossos clientes ao longo dos últimos anos. <br></br> Abaixo estão presentes todos os cursos que a FarmIN - Livestock Trainings teve o previlégio de realizar.</div>

      <div className="slider-wrapper">

      <Slider {...settingsMobile} className="sliderMobile">

      <div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
                  <div className="cat-icon">
                  <img src={icone5} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Matadouro Pequenos Ruminantes
              </div>
               </div>
               <div className="row-2">
               <img className="imagem" src={pal3} alt="" />
                  <div className="nome inter-bold">
                  Joaquim  Pallisera Lloveras 
                  </div>
               </div>
               <div className="row-3">
         
               </div>
            </div>
            </div>
      <div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
                  <div className="cat-icon">
                  <img src={icone5} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Gestão e saúde de engordas de borregos

              </div>
               </div>


               <div className="row-2-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal25} alt="" />
                  <div className="nome inter-bold">
                  Dr. José Maria Bello Dronda
                  </div>
               </div>
               <div className="palestrante">

               <img className="imagem" src={pal24} alt="" />
               <div className="nome inter-bold">
               Dr. José Maria Gonzalez Sainz
               </div>
               </div>
               </div>

      
               <div className="row-3">
         
               </div>
            </div>
            </div>
      <div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
                  <div className="cat-icon">
                  <img src={icone2} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Auditor Interno em Bem-estar de galinhas poedeiras
              </div>
               </div>
               <div className="row-2">
               <img className="imagem" src={pal33} alt="" />
                  <div className="nome inter-bold">
                  Diogo Gama
                  </div>
               </div>
               <div className="row-3">
         
               </div>
            </div>
            </div>

      <div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
                  <div className="cat-icon">
                  <img src={icone4} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Qualidade da Água - Peça fundamental para produção suína
              </div>
               </div>
               <div className="row-2">
               <img className="imagem" src={pal27} alt="" />
                  <div className="nome inter-bold">
                  Dr. Pedro Jose Hernandez de la Cruz
                  </div>
               </div>
               <div className="row-3">
         
               </div>
            </div>
            </div>

      <div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
                  <div className="cat-icon">
                  <img src={icone4} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Curso de auditores Welfare Quality - Engorda de Suínos
              </div>
               </div>
               <div className="row-2">
               <img className="imagem" src={pal26} alt="" />
                  <div className="nome inter-bold">
                     Rui Cordeiro
                  </div>
               </div>
               <div className="row-3">
         
               </div>
            </div>
            </div>

      <div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
                  <div className="cat-icon">
                  <img src={icone1} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Como construir uma vacada funcional, eficiente e sustentável
              </div>
               </div>
               <div className="row-2">
               <img className="imagem" src={pal1} alt="" />
                  <div className="nome inter-bold">
                     PJ Budler
                  </div>
               </div>
               <div className="row-3">
         
               </div>
            </div>
            </div>
            <div>
            <div className="card">
               <div className="row-1">
                  <div className="icon-section">
               <div className="cat-icon">
                  <img src={icone1} alt=""/>
                  </div>
                  <div className="cat-icon">
                  <img src={icone2} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Ecografia e palpação retal em bovinos
              </div>
               </div>
               <div className="row-2-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal7} alt="" />
                  <div className="nome inter-bold">
                  Rui d’Orey Branco 
                  </div>
               </div>
               <div className="palestrante">

               <img className="imagem" src={pal15} alt="" />
               <div className="nome inter-bold">
               Pedro Meireles
               </div>
               </div>
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/ecografia-palpacao/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div>
            </div>
            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
               <div className="cat-icon">
                  <img src={icone1} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Curso de auditores Welfare Quality: Matadouro bovinos
              </div>
               </div>
               <div className="row-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal2} alt="" />
                  <div className="nome inter-bold">
                 Antoni Dalmau Bueno
                  </div>
               </div>
    
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/WQ-matadouro-bovinos/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div>
            </div>
            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
               <div className="cat-icon">
                  <img src={icone1} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Qualidade na produção de carne de bovino: BEEFexperience
              </div>
               </div>
               <div className="row-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal4} alt="" />
                  <div className="nome inter-bold">
                 Phill Bass
                  </div>
               </div>
    
               </div>
               <div className="row-3">
                  
               </div>
            </div>
            </div>
            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
               <div className="cat-icon">
                  <img src={icone1} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Pecuária Nespresso: O novo paradigma da carne bovina
              </div>
               </div>
               <div className="row-2">
               <img className="imagem" src={pal5} alt="" />
                  <div className="nome inter-bold">
                     Roberto Barcellos
                  </div>
               </div>
               <div className="row-3">
                  
               </div>
            </div>
            </div>
            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
               <div className="cat-icon">
                  <img src={icone1} alt=""/>
                  </div>
                  <div className="cat-icon">
                  <img src={icone2} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
             Temple Grandin Show - Behavior and management in cattle production
              </div>
               </div>
               <div className="row-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal17} alt="" />
                  <div className="nome inter-bold">
                  Temple Grandin
                  </div>
               </div>
      
               </div>
               <div className="row-3">
                  {/* <button className="btn-video inter-regular">
                     Aftermovie do evento
                  </button> */}
               </div>
            </div>
            </div>
            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
               <div className="cat-icon">
                  <img src={icone1} alt=""/>
                  </div>
                  <div className="cat-icon">
                  <img src={icone2} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Formação de Agentes de Inseminação Artificial em bovinos
              </div>
               </div>
               <div className="row-2-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal7} alt="" />
                  <div className="nome inter-bold">
                  Rui d’Orey Branco
                  </div>
               </div>
               <div className="palestrante">

                  <img className="imagem" src={pal14} alt="" />
                  <div className="nome inter-bold">
                  Nestor C. e Silva
                  </div>
                  </div>
    
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/IA/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div>
            </div>
            <div>

<div className="card">
   <div className="row-1">
   <div className="icon-section">
           
                  <div className="cat-icon">
                  <img src={icone2} alt=""/>
                  </div>
                  </div>
  <div className="titulo-mc inter-bold">
   Masterclass
  </div>
  <div className="titulo-mc opacity inter-regular">
  Auditor de bem-estar em bovinos de leite
  </div>
   </div>
   <div className="row-2">
      <div className="palestrante">

       <img className="imagem" src={pal6} alt="" />
      <div className="nome inter-bold">
      George Stilwell
      </div>
   </div>


   </div>
   <div className="row-3">
   <div className="button-pastcourse">
               <Link to="/WQ-bovinos-leite/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
   </div>
</div>


</div>


<div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
           
           <div className="cat-icon">
           <img src={icone3} alt=""/>
           </div>
           </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Auditor de bem-estar em galinhas poedeiras
              </div>
               </div>
               <div className="row-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal18} alt="" />
                  <div className="nome inter-bold">
                  Dra. Aida Xercavins Simó
                  </div>
               </div>
            
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/WQ-galinhas-poedeiras/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div>
            </div>
            <div>
            {/* <div className="card">
               <div className="row-1">
               <div className="icon-section">
           
           <div className="cat-icon">
           <img src={icone3} alt=""/>
           </div>
           </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Escola Ibérica de Processamento Avícola
              </div>
               </div>
               <div className="row-2-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal9} alt="" />
                  <div className="nome inter-bold">
                  Marcos Sanchez-Plata
                  </div>
               </div>
               <div className="palestrante">

               <img className="imagem" src={pal16} alt="" />
               <div className="nome inter-bold">
               Fábio Nunes
               </div>
               </div>
      
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/EPA/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div> */}
            </div>
            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
           
           <div className="cat-icon">
           <img src={icone3} alt=""/>
           </div>
           </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Auditor de bem-estar em exploração de perus
              </div>
               </div>
               <div className="row-2-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal13} alt="" />
                  <div className="nome inter-bold">
                 Immaculada Estevez
                  </div>
               </div>
               <div className="palestrante">

                  <img className="imagem" src={pal8} alt="" />
                  <div className="nome inter-bold">
                  Xavier Averós
                  </div>
                  </div>
    
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/WQ-perus/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div>
            </div>


            <div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
           
           <div className="cat-icon">
           <img src={icone5} alt=""/>
           </div>
           </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Auditor de bem-estar em pequenos ruminantes leiteiros
              </div>
               </div>
               <div className="row-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal6} alt="" />
                  <div className="nome inter-bold">
                  George Stilwell
                  </div>
               </div>
            
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/WQ-ovelhas-cabras-leite/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
              
            </div>
            </div> 
            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
           
           <div className="cat-icon">
           <img src={icone5} alt=""/>
           </div>
           </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Inseminação Artificial e Ciclo Reprodutivo em Pequenos Ruminantes
              </div>
               </div>
               <div className="row-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal14} alt="" />
                  <div className="nome inter-bold">
                  Nestor C. e Silva
                  </div>
               </div>
          
      
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/IA-pequenos-ruminantes/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>               </div>
            </div>
            </div>

            <div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
           
           <div className="cat-icon">
           <img src={icone4} alt=""/>
           </div>
           </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Curso de auditores Welfare Quality - Porcas reprodutoras e leitões
              </div>
               </div>
               <div className="row-2-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal3} alt="" />
                  <div className="nome inter-bold">
                  Quim Pallisera Lloveras
                  </div>
               </div>
               <div className="palestrante">

               <img className="imagem" src={pal2} alt="" />
               <div className="nome inter-bold">
               Antoni Dalmau Bueno
               </div>
               </div>
            
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/WQ-porcas-reprodutoras-leitoes/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div>
            </div>
            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
           
           <div className="cat-icon">
           <img src={icone4} alt=""/>
           </div>
           </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Curso de auditores Welfare Quality - Matadouro Suínos
              </div>
               </div>
               <div className="row-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal3} alt="" />
                  <div className="nome inter-bold">
                  Quim Pallisera Lloveras
                  </div>
               </div>
          
      
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/WQ-matadouro-suinos/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div>
            </div>

            {/* <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
           
           <div className="cat-icon">
           <img src={icone6} alt=""/>
           </div>
           </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Curso Avançado de Necropsias e Urgências em Coelhos
              </div>
               </div>
               <div className="row-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal19} alt="" />
                  <div className="nome inter-bold">
                  Samuel Boucher
                  </div>
               </div>
          
      
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/necropsias-urgencias-coelhos/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div>
            </div> */}

      </Slider>
      <Slider {...settings} className="sliderDesktop">

      <div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
                  <div className="cat-icon">
                  <img src={icone5} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Matadouro Pequenos Ruminantes
              </div>
               </div>
               <div className="row-2">
               <img className="imagem" src={pal3} alt="" />
                  <div className="nome inter-bold">
                  Joaquim  Pallisera Lloveras 
                  </div>
               </div>
               <div className="row-3">
         
               </div>
            </div>
            </div>
      <div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
                  <div className="cat-icon">
                  <img src={icone5} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Gestão e saúde de engordas de borregos

              </div>
               </div>


               <div className="row-2-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal25} alt="" />
                  <div className="nome inter-bold">
                  Dr. José Maria Bello Dronda
                  </div>
               </div>
               <div className="palestrante">

               <img className="imagem" src={pal24} alt="" />
               <div className="nome inter-bold">
               Dr. José Maria Gonzalez Sainz
               </div>
               </div>
               </div>

      
               <div className="row-3">
         
               </div>
            </div>
            </div>
      <div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
                  <div className="cat-icon">
                  <img src={icone2} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Auditor Interno em Bem-estar de galinhas poedeiras
              </div>
               </div>
               <div className="row-2">
               <img className="imagem" src={pal33} alt="" />
                  <div className="nome inter-bold">
                  Diogo Gama
                  </div>
               </div>
               <div className="row-3">
         
               </div>
            </div>
            </div>

      <div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
                  <div className="cat-icon">
                  <img src={icone4} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Qualidade da Água - Peça fundamental para produção suína
              </div>
               </div>
               <div className="row-2">
               <img className="imagem" src={pal27} alt="" />
                  <div className="nome inter-bold">
                  Dr. Pedro Jose Hernandez de la Cruz
                  </div>
               </div>
               <div className="row-3">
         
               </div>
            </div>
            </div>

      <div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
                  <div className="cat-icon">
                  <img src={icone4} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Curso de auditores Welfare Quality - Engorda de Suínos
              </div>
               </div>
               <div className="row-2">
               <img className="imagem" src={pal26} alt="" />
                  <div className="nome inter-bold">
                     Rui Cordeiro
                  </div>
               </div>
               <div className="row-3">
         
               </div>
            </div>
            </div>


         <div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
                  <div className="cat-icon">
                  <img src={icone1} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Como construir uma vacada funcional, eficiente e sustentável
              </div>
               </div>
               <div className="row-2">
               <img className="imagem" src={pal1} alt="" />
                  <div className="nome inter-bold">
                     PJ Budler
                  </div>
               </div>
               <div className="row-3">
         
               </div>
            </div>
            </div>
            <div>
            <div className="card">
               <div className="row-1">
                  <div className="icon-section">
               <div className="cat-icon">
                  <img src={icone1} alt=""/>
                  </div>
                  <div className="cat-icon">
                  <img src={icone2} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Ecografia e palpação retal em bovinos
              </div>
               </div>
               <div className="row-2-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal7} alt="" />
                  <div className="nome inter-bold">
                  Rui d’Orey Branco 
                  </div>
               </div>
               <div className="palestrante">

               <img className="imagem" src={pal15} alt="" />
               <div className="nome inter-bold">
               Pedro Meireles
               </div>
               </div>
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/ecografia-palpacao/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div>
            </div>
            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
               <div className="cat-icon">
                  <img src={icone1} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Curso de auditores Welfare Quality: Matadouro bovinos
              </div>
               </div>
               <div className="row-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal2} alt="" />
                  <div className="nome inter-bold">
                 Antoni Dalmau Bueno
                  </div>
               </div>
    
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/WQ-matadouro-bovinos/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div>
            </div>
            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
               <div className="cat-icon">
                  <img src={icone1} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Qualidade na produção de carne de bovino: BEEFexperience
              </div>
               </div>
               <div className="row-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal4} alt="" />
                  <div className="nome inter-bold">
                 Phill Bass
                  </div>
               </div>
    
               </div>
               <div className="row-3">
                  
               </div>
            </div>
            </div>
            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
               <div className="cat-icon">
                  <img src={icone1} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Pecuária Nespresso: O novo paradigma da carne bovina
              </div>
               </div>
               <div className="row-2">
               <img className="imagem" src={pal5} alt="" />
                  <div className="nome inter-bold">
                     Roberto Barcellos
                  </div>
               </div>
               <div className="row-3">
                  
               </div>
            </div>
            </div>
            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
               <div className="cat-icon">
                  <img src={icone1} alt=""/>
                  </div>
                  <div className="cat-icon">
                  <img src={icone2} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
             Temple Grandin Show - Behavior and management in cattle production
              </div>
               </div>
               <div className="row-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal17} alt="" />
                  <div className="nome inter-bold">
                  Temple Grandin
                  </div>
               </div>
      
               </div>
               <div className="row-3">
                  {/* <button className="btn-video inter-regular">
                     Aftermovie do evento
                  </button> */}
               </div>
            </div>
            </div>
            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
               <div className="cat-icon">
                  <img src={icone1} alt=""/>
                  </div>
                  <div className="cat-icon">
                  <img src={icone2} alt=""/>
                  </div>
                  </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Formação de Agentes de Inseminação Artificial em bovinos
              </div>
               </div>
               <div className="row-2-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal7} alt="" />
                  <div className="nome inter-bold">
                  Rui d’Orey Branco
                  </div>
               </div>
               <div className="palestrante">

                  <img className="imagem" src={pal14} alt="" />
                  <div className="nome inter-bold">
                  Nestor C. e Silva
                  </div>
                  </div>
    
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/IA/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div>
            </div>
            <div>

<div className="card">
   <div className="row-1">
   <div className="icon-section">
           
                  <div className="cat-icon">
                  <img src={icone2} alt=""/>
                  </div>
                  </div>
  <div className="titulo-mc inter-bold">
   Masterclass
  </div>
  <div className="titulo-mc opacity inter-regular">
  Auditor de bem-estar em bovinos de leite
  </div>
   </div>
   <div className="row-2">
      <div className="palestrante">

       <img className="imagem" src={pal6} alt="" />
      <div className="nome inter-bold">
      George Stilwell
      </div>
   </div>


   </div>
   <div className="row-3">
   <div className="button-pastcourse">
               <Link to="/WQ-bovinos-leite/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
   </div>
</div>


</div>


<div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
           
           <div className="cat-icon">
           <img src={icone3} alt=""/>
           </div>
           </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Auditor de bem-estar em galinhas poedeiras
              </div>
               </div>
               <div className="row-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal18} alt="" />
                  <div className="nome inter-bold">
                  Dra. Aida Xercavins Simó
                  </div>
               </div>
            
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/WQ-galinhas-poedeiras/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div>
            </div>
            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
           
           <div className="cat-icon">
           <img src={icone3} alt=""/>
           </div>
           </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Escola Ibérica de Processamento Avícola
              </div>
               </div>
               <div className="row-2-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal9} alt="" />
                  <div className="nome inter-bold">
                  Marcos Sanchez-Plata
                  </div>
               </div>
               <div className="palestrante">

               <img className="imagem" src={pal16} alt="" />
               <div className="nome inter-bold">
               Fábio Nunes
               </div>
               </div>
      
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/EPA/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div>
            </div>
            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
           
           <div className="cat-icon">
           <img src={icone3} alt=""/>
           </div>
           </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Auditor de bem-estar em exploração de perus
              </div>
               </div>
               <div className="row-2-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal13} alt="" />
                  <div className="nome inter-bold">
                 Immaculada Estevez
                  </div>
               </div>
               <div className="palestrante">

                  <img className="imagem" src={pal8} alt="" />
                  <div className="nome inter-bold">
                  Xavier Averós
                  </div>
                  </div>
    
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/WQ-perus/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div>
            </div>


            <div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
           
           <div className="cat-icon">
           <img src={icone5} alt=""/>
           </div>
           </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Auditor de bem-estar em pequenos ruminantes leiteiros
              </div>
               </div>
               <div className="row-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal6} alt="" />
                  <div className="nome inter-bold">
                  George Stilwell
                  </div>
               </div>
            
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/WQ-ovelhas-cabras-leite/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
              
            </div>
            </div> 
            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
           
           <div className="cat-icon">
           <img src={icone5} alt=""/>
           </div>
           </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Inseminação Artificial e Ciclo Reprodutivo em Pequenos Ruminantes
              </div>
               </div>
               <div className="row-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal14} alt="" />
                  <div className="nome inter-bold">
                  Nestor C. e Silva
                  </div>
               </div>
          
      
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/IA-pequenos-ruminantes/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>               </div>
            </div>
            </div>

            <div>
         <div className="card">
               <div className="row-1">
               <div className="icon-section">
           
           <div className="cat-icon">
           <img src={icone4} alt=""/>
           </div>
           </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Curso de auditores Welfare Quality - Porcas reprodutoras e leitões
              </div>
               </div>
               <div className="row-2-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal3} alt="" />
                  <div className="nome inter-bold">
                  Quim Pallisera Lloveras
                  </div>
               </div>
               <div className="palestrante">

               <img className="imagem" src={pal2} alt="" />
               <div className="nome inter-bold">
               Antoni Dalmau Bueno
               </div>
               </div>
            
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/WQ-porcas-reprodutoras-leitoes/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div>
            </div>
            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
           
           <div className="cat-icon">
           <img src={icone4} alt=""/>
           </div>
           </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Curso de auditores Welfare Quality - Matadouro Suínos
              </div>
               </div>
               <div className="row-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal3} alt="" />
                  <div className="nome inter-bold">
                  Quim Pallisera Lloveras
                  </div>
               </div>
          
      
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/WQ-matadouro-suinos/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div>
            </div>

            <div>
            <div className="card">
               <div className="row-1">
               <div className="icon-section">
           
           <div className="cat-icon">
           <img src={icone6} alt=""/>
           </div>
           </div>
              <div className="titulo-mc inter-bold">
               Masterclass
              </div>
              <div className="titulo-mc opacity inter-regular">
              Curso Avançado de Necropsias e Urgências em Coelhos
              </div>
               </div>
               <div className="row-2">
                  <div className="palestrante">

                   <img className="imagem" src={pal19} alt="" />
                  <div className="nome inter-bold">
                  Samuel Boucher
                  </div>
               </div>
          
      
               </div>
               <div className="row-3">
               <div className="button-pastcourse">
               <Link to="/necropsias-urgencias-coelhos/">
                    <button class="btn-past-course inter-bold">Ver Curso</button> 
                    </Link>
                  </div>
               </div>
            </div>
            </div>

      </Slider>
      </div>


   


   </div>


   <div id="section-fotos-about-index">
      <Slider {...settingsaboutdesktop} className="slider-fotos-desktop">
      <div >
      <img src={fotoslider12} alt="" />
      </div>
      <div >
      <img src={fotoslider13} alt="" />
      </div>
      <div>
        <img src={fotoslider1} alt="" />
      </div>
      <div>
      <img src={fotoslider2} alt="" />
      </div>
      <div >
      <img src={fotoslider3} alt="" />
      </div>
      <div >
      <img src={fotoslider4} alt="" />
      </div>
      <div >
      <img src={fotoslider5} alt="" />
      </div>
      <div >
      <img src={fotoslider6} alt="" />
      </div>

      <div >
      <img src={fotoslider7} alt="" />
      </div>
      <div >
      <img src={fotoslider8} alt="" />
      </div>
      <div >
      <img src={fotoslider9} alt="" />
      </div>
      <div >
      <img src={fotoslider10} alt="" />
      </div>
      <div >
      <img src={fotoslider11} alt="" />
      </div>
     
   
    
      </Slider>

      <Slider {...settingsaboutmobile} className="slider-fotos-mobile">
      <div >
      <img src={fotoslider12} alt="" />
      </div>
      <div >
      <img src={fotoslider13} alt="" />
      </div>
      <div>
        <img src={fotoslider1} alt="" />
      </div>
      <div>
      <img src={fotoslider2} alt="" />
      </div>
      <div >
      <img src={fotoslider3} alt="" />
      </div>
      <div >
      <img src={fotoslider4} alt="" />
      </div>
      <div >
      <img src={fotoslider5} alt="" />
      </div>
      <div >
      <img src={fotoslider6} alt="" />
      </div>

      <div >
      <img src={fotoslider7} alt="" />
      </div>
      <div >
      <img src={fotoslider8} alt="" />
      </div>
      <div >
      <img src={fotoslider9} alt="" />
      </div>
      <div >
      <img src={fotoslider10} alt="" />
      </div>
      <div >
      <img src={fotoslider11} alt="" />
      </div>
     
   
    
      </Slider>
    </div>
   <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div>
   {/* <div id="popup-temple">
      <div className="wrapper-popup">
         <video className="video-class"  preload='auto'  autoPlay loop playsInline muted poster=
"https://farmin-trainings.net/poster-video.png" >
            <source src={videotemple}  type="video/mp4" />
         </video>
      </div>
   </div> */}
   


   <Footer/>
</Layout>
  );
};
export default IndexPage