import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/nestor.jpg" // Tell webpack this JS file uses this image
import pal13 from "../images/palestrantes/pedromeireles.jpg" // Tell webpack this JS file uses this image
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import Footer from "../components/footer"

import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image
import logo5 from "../images/logos/westpoint.jpg" // Tell webpack this JS file uses this image
import logo6 from "../images/logos/atm.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image
import foto6 from "../images/fotoscursos/foto22.jpg" // Tell webpack this JS file uses this image
import foto7 from "../images/fotoscursos/foto23.jpg" // Tell webpack this JS file uses this image
import foto8 from "../images/fotoscursos/foto24.jpg" // Tell webpack this JS file uses this image
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';
import UseAnimations from 'react-useanimations';
var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
            <div className="texto inter-bold">
             <span className="opacity"> Gestão reprodutiva e ecografia</span> <br></br>
              bovina avançada<br></br>
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal7} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                     Rui d´Orey Branco
                      </div>
                    
                    <div className="titulo inter-light">
                    Prof. Universitário, PhD em fisiologia reprodutiva, consultor especializado em reprodução e bem-estar de bovinos
                      </div>
                      </div>
                    
                  </div>
                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal13} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Pedro Meireles
                      </div>
                    
                    <div className="titulo inter-light">
                    Consultor especialista em fertilidade e gestão de unidades de produção leiteira
                      </div>
                      </div>
                    
                  </div>

            
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                        28 e 29 de Março de 2025
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                     Português
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                     500€ + IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/gestao-reprodutiva.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="https://forms.gle/GkGbnJiuMrbsudbt9" target="_blank" >
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotomasterclass} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Descrição
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc inter-light">Este curso avançado de gestão
reprodutiva e ecografia bovina visa
capacitar os participantes a tomar
decisões estratégicas na reprodução,
fornecendo ferramentas de uso avançado
em ecografia. Com foco em conceitos
avançados e práticos, o curso será
ministrado por Rui d'Orey e Pedro Meireles,
especialistas reconhecidos na área.<br></br>
              Os participantes serão desafiados a aplicar conhecimentos em
situações reais de gestão reprodutiva em explorações. A forte
componente prática do curso inclui dois blocos de prática de
ecografia, onde serão abordadas técnicas como sexagem e
identificação de anomalias típicas. Os participantes terão a
oportunidade de analisar casos reais, desenvolvendo habilidades
que podem ser aplicadas diretamente em suas atividades
profissionais.
                </div>
                <div className="logos">
                  
                <img src={logo5} alt="" />
                <img src={logo6} alt="" />

                  </div>
            </div>
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
          <div className="text inter-regular">
          Os cursos intensivos FarmIN têm um porgrama detalhado que possibilita ao participante observar as diversas áreas abrangidas pelo curso, os diferentes módulos e a atividade dos palestrantes ao longo do dia. 
          
            </div>
      </div>
      <div className="wrapper-program">





      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            + Dia 1 - 28/03/2025 - Gestão Reprodutiva e Práticas
                            </Accordion.Header>

                            <Accordion.Body>
                               
                                <div class="modulo">
                                <p><span className="date">09:00 - 11:00</span> | <span class="mod">Módulo 1</span> - <span class="txt"><b>Princípios Avançados da Ecografia Bovina</b><br></br>

Equipamentos avançados e
técnicas de utilização  </span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">11:00 - 11:15</span> | <span className="mod">Coffee break</span> </p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:15 - 13:15</span> | <span className="mod">Módulo 2</span> - <span class="txt"><b>Avanços Recentes em Pesquisa
Reprodutiva</b><br></br>
Discussão sobre as últimas pesquisas e suas aplicações
práticas  </span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:15 - 14:30</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:30 - 16:30</span> | <span className="mod">Módulo 3</span> - <span class="txt"><b>Fisiologia Reprodutiva Avançada</b><br></br>
                                  Revisão de conceitos avançados em fisiologia reprodutiva </span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:30 - 16:45</span> | <span className="mod">Coffee break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:45 - 18:00</span> | <span className="mod">Módulo 4</span> - <span class="txt"><b>Prática de Ecografia I (On Farm)</b><br></br>
Sessão prática focada em sexagem e identificação de
anomalias típicas em imagens ecográficas </span></p>
                                </div>
                               
          
                    

                                
                                </Accordion.Body>

                                </Accordion.Item>
                        

                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            +   Dia 2 - 29/03/2025 - Ecografia Avançada e Comunicação Estratégica
                            </Accordion.Header>

                            <Accordion.Body>
                                <div class="modulo">
                                <p><span className="date">09:00 - 11:00</span> | <span class="mod">Módulo 5</span> - <span class="txt"><b>Análise de Dados na
Gestão Reprodutiva</b><br></br>
Coleta e interpretação de dados
para tomada de decisões</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">11:00 - 11:15</span> | <span className="mod">Coffee break</span> </p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:15 - 13:15</span> | <span className="mod">Módulo 6</span> - <span class="txt"><b>Comunicação com o Produtor</b><br></br>
Técnicas para comunicação eficaz e construção de
relacionamentos de confiança </span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:15 - 14:30</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:30 - 16:30</span> | <span className="mod">Módulo 7</span> - <span class="txt"><b>Desenvolvimento de Planos Estratégicos</b><br></br>
Reprodutivos
Estruturação e implementação de um plano reprodutivo eficaz</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:30 - 16:45</span> | <span className="mod">Coffee break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:45 - 18:00</span> | <span className="mod">Módulo 8</span> - <span class="txt"><b>Casos Reais de Gestão da Reprodução</b>
                                  Discussão de casos práticos e suas soluções</span></p>
                                </div>
          
                                </Accordion.Body>
                                </Accordion.Item>

                                
      
                    </Accordion>
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/gestao-reprodutiva.pdf" target="_blank" >
                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                
                <div className="form">
                <a href="https://forms.gle/GkGbnJiuMrbsudbt9" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
          </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal7} alt="" />

              </div>
              <div className="nome">
              Rui d´Orey Branco
              </div>
              <div className="titulo">
                 Consultor especialista em reprodução e bem-estar
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Fundador da CONCERTA International Consulting, uma consultora
especializada em soluções na área de Food and Agribusiness

</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Professor na Universidade Lusófona
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Doutorado em Fisiologia Reprodutiva - Texas AM
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Doutoramento em fisiologia reprodutiva – Texas A&M

</p>
<p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
Ronnie excellence Teaching award

</p>
                  

                 
                  </div>
              </div>
            </div>
        </div>
        </div>
        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal13} alt="" />

              </div>
              <div className="nome">
              Pedro Meireles
              </div>
              <div className="titulo">
              Consultor e especialista em reprodução de bovinos leiteiros
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Médico Veterinário graduado em 2001 na faculdade de Medicina
Veterinária, Instituto de Ciências Biomédicas de Abel Salazar

</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  A sua principal área de interesse é a Fertilidade e Apoio à Gestão
de Unidades de Produção Leiteira.
</p>
                    
                  </div>
              </div>
            </div>
        </div>
        </div>

   
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>
      <div>
        <img src={foto6} alt="" />
      </div>
      <div>
        <img src={foto7} alt="" />
      </div>
      <div>
        <img src={foto8} alt="" />
      </div>
      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>
      <div>
        <img src={foto6} alt="" />
      </div>
      <div>
        <img src={foto7} alt="" />
      </div>
      <div>
        <img src={foto8} alt="" />
      </div>
      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
